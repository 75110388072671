import React, { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../common";
import { internationalFormattedValue, SequenceUtils } from "@exportx/ui-utils";
import { Table, Typography, Col, Card, Row, Flex, Divider, Popover } from "antd";
import { AdvanceEnumType, CommercialCoalCostingService, ContractModesEnum, CurrencyConverterService, CurrencyExchangeRequestDto, PerFormaReqIdDto, PriceTypeEnum } from "@exportx/shared-models-and-services";
import moment from "moment";
import { QualityPriceAdjustmentsDeatils } from "./quality-price-adjustments-deatils";
import { InfoCircleOutlined } from "@ant-design/icons";

interface CmApprovalDetailsProps {
  costingId: string;
  contractType: ContractModesEnum;
  remainingAmount?: number;
  children?: React.ReactNode;
}

export const CmApprovalDetails = (props: CmApprovalDetailsProps) => {
  const cmService = new CommercialCoalCostingService();
  const { costingId, contractType, children, remainingAmount } = props;
  const { authContext } = useAuthState();
  const [advTotal, setAdvTotal] = useState<number>(0);

  const [data, setData] = useState<any>();
  const [BargeData, setBargeData] = useState([])
  const { Text } = Typography;

  useEffect(() => {
    getAllCommercialData()
  }, [])

  const getAllCommercialData = () => {

    const req = new PerFormaReqIdDto(costingId, contractType, authContext.defaultPlant);
    cmService.getAllCommercialCostingsData(req).then(async res => {
      if (res.status) {
        const { adjustedPriceValue, inWardQty, baseCurrency, costingCurrency, quoteCurrency, exchangeDate, currencyType, exchangeRateAdjustment } = res.data?.transactionBarges[0] || {};
        let exchangeRate = 1;
        let displayExchangeRate = 1;
        const currencyConverterService = new CurrencyConverterService();
        const req = new CurrencyExchangeRequestDto(baseCurrency, costingCurrency ?? quoteCurrency, moment(exchangeDate).format('YYYY-MM-DD'), currencyType, exchangeRateAdjustment)

        const currencyData = await currencyConverterService.getCurrencyExchangeRate(req);
        if (currencyData?.data)
          exchangeRate = currencyData?.data?.exchangeRate;
          displayExchangeRate = currencyData?.data?.displayExchangeRate;

        const advTotal = Number(
          res.data?.advanceTarnsactionsData?.reduce((a, c) => a + Number(c.totalAdjustedAmount || 0), 0).toFixed(2)
        );

        const qty = inWardQty ? Number(inWardQty) : 0

        const price = isNaN(Number(adjustedPriceValue)) ? 0 : Number(adjustedPriceValue);
        const excgAfterAdjustment = (isNaN(Number(exchangeRate)) ? 1 : Number(exchangeRate));

        const priceAfterExchg = price * excgAfterAdjustment;

        const totalCoalPrice = res?.data?.price_type === PriceTypeEnum.LUMP_SUM
          ? Number(res?.data?.adjustedPriceValue || 0)
          : qty * priceAfterExchg;

        const fcCharges = Number(res?.data?.fcCharges) || 0;
        const amountBeforeTax = totalCoalPrice + fcCharges;

        const tax1Perc = Number(res.data?.taxPercentage1) || 0;
        const tax2Perc = Number(res.data?.taxPercentage2) || 0;
        const tdsPerc = Number(res.data?.tdsPercentage) || 0;

        const taxTotal1 = amountBeforeTax * (tax1Perc / 100);
        const taxTotal2 = amountBeforeTax * (tax2Perc / 100);
        const tdsTotal = amountBeforeTax * (tdsPerc / 100);

        const amountAfterTax = amountBeforeTax + taxTotal1 + taxTotal2 - tdsTotal;

        let freightTotalAmt = 0;
        if (res?.data?.includeFreight) {
          freightTotalAmt = Number(res?.data?.freightCostingTotal) || 0;
        }

        const totalPayable = amountAfterTax + freightTotalAmt - advTotal;

        setData({
          ...res.data,
          totalPayable,
          totalCoalPrice,
          amountBeforeTax,
          fcCharges,
          amountAfterTax,
          exchangeRate,
          exchangeRateAdjustment,
          priceAfterExchg,
          taxTotal1,
          taxTotal2,
          tdsTotal,
          displayExchangeRate,
          includeFreight: !!res.data?.includeFreight,  // Simplified boolean check
        });
        // const bargeDetails = res.data?.transactionBarges?.map((item) => ({ ...item, businessNo: res.data?.bnId, incoterm: res.data?.incoterm, advAdjustment: advTotal, paid: 0 }))
        const bargeDetails = res.data?.transactionBarges?.map((item) => ({
          ...item, incoterm: res.data?.incoterm, advAdjustment: advTotal,
          totalPayable: totalPayable,
          adjustedPriceValue: res.data?.adjustedPriceValue,
          contractId: res.data?.contractId,
          contractNo: res?.data?.contractNo,
          layCan: `${moment(res?.data?.laycanFromDate)?.format('DD-MMM-YY')} - ${moment(res?.data?.laycanToDate)?.format('DD-MMM-YY')} (${res?.data?.quality || '-'})`,
          remaining: + res.data?.invoiceTotal

        }))
        setBargeData(bargeDetails)
        setAdvTotal(advTotal)

      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
        // setInitialValues(null);
      }
    }).catch(err => { console.log(err.message) });
  };

  const showFreightPrice = () => (contractType !== ContractModesEnum.PROCUREMENT_FEES && contractType !== ContractModesEnum.MARKETING_FEES)

  const sharedOnCell = (_, index?: number) => {
    if (index == 0) return { rowSpan: BargeData.length }
    else return { rowSpan: 0 }
  };

  const columns = [
    {
      title: 'Barge ID',
      dataIndex: 'bargeNo',
      key: 'bargeNo',
      render: (value, record) => {
        if (record.bgUId) {
          const link = `/#/barge-detail-view?barge_id=${record.bgUId}`;
          return <div>
            <a href={link} className="link" target="_blank">
              {SequenceUtils.formatNumberToSpecificLength(record.bargeNo)}
            </a>
            <Typography className="small-text"> {record.bgName}</Typography>
          </div>

        }
        return <div>
          <Text className="small-text"> {value}</Text>
          <Typography className="small-text"> {record.bgName}</Typography>
        </div>

      }
    },
    {
      title: 'Business No',
      dataIndex: 'businessNo',
      key: 'businessNo',
      render: (value, record) => {
        if (record.bnId) {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <div>
            <a href={link} className="link" target="_blank">{record.businessNo}</a>
            <Typography className="small-text"> {record.mvName}</Typography>
          </div>

        }

      }
    },
    {
      title: 'Contract No',
      dataIndex: 'contractNo',
      key: 'contractNo',
      render: (value, record) => {
        if (record.contractId) {
          let link = `/#/po-detail-view?pc_id=${record.contractId}`
          if (contractType === ContractModesEnum.SALES) {
            link = `/#/so-detail-view?sc_id=${record.contractId}`;
          }
          if (contractType === ContractModesEnum.PROCUREMENT_FEES) {
            link = `/#/procurement-contract-detailedView?cpcId=${record.contractId}`
          }
          if (contractType === ContractModesEnum.MARKETING_FEES) {
            link = `/#/marketing-detailedView?cpcId=${record.contractId}`

          }
          return <a href={link} className="link" target="_blank">
            {record.contractNo}
          </a>

        }
      }
    },
    {
      title: 'Laycan (Quality)',
      dataIndex: 'layCan',
      key: 'layCan',
      onCell: sharedOnCell,
      render: (value, record) => <>
        <Typography className="small-text">{record.layCan ?? 'NA'}</Typography>
        <Typography className="small-text">{record.incoterm ?? 'NA'}</Typography>
      </>
    },
    {
      title: 'Quantity',
      dataIndex: 'inWardQty',
      key: 'inWardQty',
      onCell: sharedOnCell,
      render: (value, record) => <Text className="small-text">{internationalFormattedValue(value, 3)}</Text>
    },
    {
      title: `${data?.price_type === PriceTypeEnum.LUMP_SUM ? 'Lumpsum' : 'Price PMT'}`,
      dataIndex: 'adjustedPriceValue',
      key: 'adjustedPriceValue',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.adjustedPriceValue, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },
    ...showFreightPrice() ? [{
      title: 'Freight Price',
      dataIndex: 'freightPricePmt',
      key: 'freightPricePmt',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.freightPricePmt, 3)}</Text>
        <span className="currency-style">{record?.baseCurrency}</span>

      </>

    },] : [],

    // {
    //     title: 'Price',
    //     dataIndex: 'exchangeRate',
    //     key: 'exchangeRate',
    //     onCell: sharedOnCell,
    //     render: (text, record) => <span>{getLocalFormat(record?.exchangeRate, record?.quoteCurrency || record?.baseCurrency)}</span>
    // },
    {
      title: 'Total Payable',
      dataIndex: 'totalPayable',
      key: 'totalPayable',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.totalPayable, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    {
      title: 'Advance',
      dataIndex: 'advAdjustment',
      key: 'advAdjustment',
      onCell: sharedOnCell,
      render: (text, record) => <>
        <Text className="small-text">{internationalFormattedValue(record?.advAdjustment, 3)}</Text>
        <span className="currency-style">{record?.quoteCurrency || record?.baseCurrency}</span>

      </>

    },
    // {
    //   title: 'Balance',
    //   dataIndex: 'remaining',
    //   key: 'remaining',
    //   onCell: sharedOnCell,
    //   render: (text, record) => <>
    //     <Text className="small-text">{internationalFormattedValue(record?.remaining, 3)}</Text>
    //     <span className="currency-style">{record?.quoteCurrency}</span>

    //   </>

    // },
  ];

  return <>
    <Row gutter={8}>
      <Col span={16}>
        <Card
          className="card-shadow"
          styles={{ body: { padding: '0px 10px 15px' } }}
          style={{ marginBottom: 8 }}
          title={<h6>Barge Details</h6>}
        >
          <Table
            className='contracts-list'
            dataSource={BargeData}
            columns={columns}
            size="small"
            pagination={false}
            scroll={{ x: 1200 }}
          />

        </Card>

        <QualityPriceAdjustmentsDeatils
          contractType={contractType}
          selectedRowData={BargeData[0]}
          formInitialValues={data}

        />

        {children}

      </Col >

      <Col span={8}>
        {data?.includeFreight && data?.includeFreight !== 0 &&

          <Card
            className="card-shadow"
            title={<span className="card-title-md">Freight Price Details</span>}
            styles={{ body: { padding: '6px 14px' } }}
            style={{ marginBottom: 8 }}
          >
            {BargeData.length && (<Flex justify="space-between" align='center'>
              <Text className="price-card-text">Currency Type</Text>
              <Text className="price-card-text"> {BargeData[0]?.currencyType ?? 'NA'}</Text>
            </Flex>)}
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Date
                {BargeData.length && BargeData[0]?.currencyType === "Middle Rate" && (
                  <span style={{ paddingLeft: 5 }}>
                    <Popover
                      content={
                        <>
                          <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{BargeData[0]?.purchaseRate}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Selling Rate <b>{BargeData[0]?.sellingRate}</b></Typography>
                        </>
                      }
                      title="Rates"
                      placement="bottom"
                    >
                      <InfoCircleOutlined style={{ color: "#0295D4" }} />
                    </Popover>
                  </span>
                )}
              </Text>
              <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.exchangeRate, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Freight Pmt</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightPricePmt, 3)}  {data?.baseCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Freight</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(data?.freightTotalAmt, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 1</Text>
              <Text className="price-card-text">{`${data?.freightTaxName1 || 'NA'} (${data?.freightTaxPerc1 || '0'})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightTaxTotal1, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Tax 2</Text>
              <Text className="price-card-text">{`${data?.freightTaxName2 || ''} (${data?.freightTaxPerc2 || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightTaxTotal2, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text className="price-card-text">{`${data?.freightTdsName || ''} (${data?.freightTdsPerc || ''})`}</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(data?.freightTdsTotal, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">Total Freight</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(data?.freightCostingTotal, 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>

          </Card>
        }
        <Card
          className="card-shadow"
          title={<span className="card-title-md">Price Details</span>}
          styles={{ body: { padding: '6px 14px' } }}
        >
          {BargeData.length && (<Flex justify="space-between" align='center'>
            <Text className="price-card-text">Currency Type</Text>
            <Text className="price-card-text"> {BargeData[0]?.currencyType ?? 'NA'}</Text>
          </Flex>)}
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Date</Text>
            <Text className="price-card-text"> {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Rate
              {BargeData.length && BargeData[0].currencyType === "Middle Rate" && (
                <span style={{ paddingLeft: 5 }}>
                  <Popover
                    content={
                      <>
                        <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{BargeData[0]?.purchaseRate}</b></Typography>
                        <Typography style={{ fontSize: 10 }}>Selling Rate <b>{BargeData[0]?.sellingRate}</b></Typography>
                      </>
                    }
                    title="Rates"
                    placement="bottom"
                  >
                    <InfoCircleOutlined style={{ color: "#0295D4" }} />
                  </Popover>
                </span>
              )}
            </Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.displayExchangeRate, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Exchange Rate Adjustment</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.exchangeRateAdjustment, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Price After Exchange Rate</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.priceAfterExchg, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Total Coal Price</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.totalCoalPrice, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">FC Charges</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.fcCharges, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-heading">Amount</Text>
            <Text className="price-card-heading">
              {internationalFormattedValue(data?.amountBeforeTax, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Divider dashed={true} />

          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Tax 1</Text>
            <Text className="price-card-text">{`${data?.taxName1 || 'NA'} (${data?.taxPercentage1 || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.taxTotal1, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Tax 2</Text>
            <Text className="price-card-text">{`${data?.taxName2 || 'NA'} (${data?.taxPercentage2 || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.taxTotal2, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>
          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">TDS</Text>
            <Text className="price-card-text">{`${data?.tdsName || 'NA'} (${data?.tdsPercentage || '0'})`}</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(data?.tdsTotal, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Divider dashed={true} />

          <Flex justify="space-between" align='center'>
            <Text className="price-card-heading">Total Amount</Text>
            <Text className="price-card-heading">
              {internationalFormattedValue(data?.amountAfterTax, 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          {data?.advanceTarnsactionsData?.map((adv) => {
            let link = `/#/advanceDetailView?id=${adv.adjustedCostingId}`;
            if (contractType === ContractModesEnum.SALES || contractType === ContractModesEnum.MARKETING_FEES) {
              link = `/#/advance-receivables-detailed-view?id=${adv.adjustedCostingId}`
            }
            if (adv.advanceType === AdvanceEnumType.Against_Performa_Invoice) {
              link = `/#/performa-pc-detail-view?costing_id=${adv.adjustedCostingId}`
            }
            return (
              <Flex justify="space-between" align='center' key={adv.id}>
                <Text className="price-card-text">Deduction (<a href={link} className="link" style={{ color: '#0083C9' }} target='_blank'>{adv.adjustedCostingNo}</a>)</Text>
                <Text className="price-card-text">
                  {internationalFormattedValue(adv?.totalAdjustedAmount, 3)}  {data?.quoteCurrency}
                </Text>
              </Flex>
            )
          })}

          <Flex justify="space-between" align='center'>
            <Text className="price-card-text">Total Advance Adjustment</Text>
            <Text className="price-card-text">
              {internationalFormattedValue(Number(advTotal), 3)}  {data?.quoteCurrency}
            </Text>
          </Flex>

          <Flex justify="space-between" align='center'>
            <Text className="price-card-green"><b>To be paid to Supplier</b></Text>
            <Text className="price-card-green">
              <b>{internationalFormattedValue(data?.totalPayable, 3)}  {data?.quoteCurrency}</b>
            </Text>
          </Flex>


          {Number(remainingAmount) > 0 &&

            <Flex justify="space-between" align='center'>
              <Text className="price-card-red">Remaining Amount</Text>
              <Text className="price-card-red">
                {internationalFormattedValue(Number(remainingAmount), 3)}  {data?.quoteCurrency}
              </Text>
            </Flex>
          }
        </Card>


      </Col>
    </Row>

    {/* <Table dataSource={BargeData} columns={columns} pagination={false} style={{ width: '100%' }} className="inner-table" />

        <Card size='small' style={{ width: '100%' }}>
            <Row gutter={20}>
                <Col span={data?.includeFreight ? 12 : 24}>
                    <table className="table table-bordered table-sm mt-4">
                        <tr>
                            <th colSpan={2}>Exchange Date</th>
                            <td>{data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}</td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Exchange Rate</th>
                            <td>
                                <Text>{getLocalFormat(data?.exchangeDate ? data?.exchangeRate : 1, data?.quoteCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Price After Exchange Rate</th>
                            <td>
                                <Text>{getLocalFormat(data?.exchangeRateValue, data?.quoteCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>Total Coal Price</th>
                            <td>
                                <Text>{getLocalFormat(data?.totalCoalPrice, data?.quoteCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th colSpan={2}>FC Charges</th>
                            <td>
                                <Text>{getLocalFormat(data?.fcCharges, data?.quoteCurrency)}</Text>

                            </td>
                        </tr>
                        {/* <tr>
                <th colSpan={2}>Freight</th>
                <td>
                    <Text>{getLocalFormat(data?.freightPricePmt, data?.quoteCurrency)}</Text>

                </td>
            </tr> */}
    {/* <tr>
                <th colSpan={2}>Amount</th>
                <td>
                    <Text>{getLocalFormat(data?.totalPayable, data?.quoteCurrency)}</Text>

                </td>
            </tr> *
                        <tr>
                            <th>TAX 1</th>
                            <td>
                                <Text>{`${data?.taxName1 || ''} - ${data?.taxPercentage1 || ''}`}</Text>
                            </td>
                            <td>{getLocalFormat(data?.taxTotal1, data?.quoteCurrency)}</td>
                        </tr>
                        <tr>
                            <th>TAX 2</th>
                            <td>
                                <Text>{`${data?.taxName2 || ''} - ${data?.taxPercentage2 || ''}`}</Text>
                            </td>
                            <td>
                                <Text>{getLocalFormat(data?.taxTotal2, data?.quoteCurrency)}</Text>
                            </td>
                        </tr>
                        <tr>
                            <th>TDS:</th>
                            <td><Text>{`${data?.tdsName || ''} - ${data?.tdsPercentage || ''}`}</Text></td>
                            <td>{getLocalFormat(data?.tdsTotal, data?.quoteCurrency)}</td>
                        </tr>
                        <tr>
                            <th colSpan={2} > Total Amount</th>
                            <td>{getLocalFormat(data?.totalPayable, data?.quoteCurrency)}</td>

                        </tr>
                        <tr>
                            <th colSpan={3} >
                                Advance Adjustments
                            </th>
                            <td></td>

                        </tr>
                        {data?.advanceTarnsactionsData?.map((adv) => (
                            <tr key={adv.id}>
                                <th colSpan={2} >
                                    Adv deduction against contract : {adv.adjustedCostingNo}
                                </th>
                                <td>{getLocalFormat(adv.totalAdjustedAmount, data?.quoteCurrency)}</td>

                            </tr>
                        ))}
                        <tr>
                            <th colSpan={2} >
                                Total Advance Adjustments
                            </th>
                            <td> <Text>{getLocalFormat(advTotal, data?.quoteCurrency)}</Text>
                            </td>

                        </tr>
                        <tr>
                            <th colSpan={2} >
                                To be paid to Supplier
                            </th>
                            <td>
                                <span style={{ color: 'green', fontWeight: 'bold' }}>{getLocalFormat(data?.invoiceTotal, data?.quoteCurrency)}</span>
                            </td>
                        </tr>
                        {props.remainingAmount &&
                            <tr>
                                <th colSpan={2} >
                                    Remaining Amount
                                </th>
                                <td>
                                    <span style={{ color: 'red', fontWeight: 'bold' }}>{getLocalFormat(props?.remainingAmount, data?.quoteCurrency)}</span>
                                </td>
                            </tr>
                        }
                    </table>
                </Col>
                {data?.includeFreight &&
                    <Col span={data?.includeFreight ? 12 : 24}>
                        <table className="table table-bordered table-sm mt-4">
                            <tr>
                                <th colSpan={3} className="text-center">Freight Charges</th>
                            </tr>
                            <tr>
                                <th colSpan={2}>Freight Pmt</th>
                                <td>
                                    <Text>{getLocalFormat(data?.freightPricePmt, data?.baseCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Exchange Date</th>
                                <td>
                                    {data?.exchangeDate ? moment(data?.exchangeDate)?.format('DD-MM-YYYY') : 'NA'}
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Exchange Rate</th>
                                <td>
                                    <Text>{getLocalFormat(data?.exchangeRate, data?.quoteCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Total Freight</th>
                                <td>
                                    <Text>{getLocalFormat(data?.freightTotalAmt, data?.quoteCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th>TAX 1</th>
                                <td>
                                    <Text>{`${data?.freightTaxName1 || ''} - ${data?.freightTaxPerc1 || ''}`}</Text>
                                </td>
                                <td>{getLocalFormat(data?.freightTaxTotal1, data?.quoteCurrency)}</td>
                            </tr>
                            <tr>
                                <th>TAX 2</th>
                                <td>
                                    <Text>{`${data?.freightTaxName2 || ''} - ${data?.freightTaxPerc2 || ''}`}</Text>
                                </td>
                                <td>
                                    <Text>{getLocalFormat(data?.freightTaxTotal2, data?.quoteCurrency)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th>TDS:</th>
                                <td><Text>{`${data?.freightTdsName || ''} - ${data?.freightTdsPerc || ''}`}</Text></td>
                                <td>{getLocalFormat(data?.freightTdsTotal, data?.quoteCurrency)}</td>
                            </tr>
                            <tr>
                                <th colSpan={2} > Total Freight Amount</th>
                                <td>{getLocalFormat(data?.freightCostingTotal, data?.quoteCurrency)}</td>

                            </tr>
                        </table>
                    </Col>}
            </Row>
        </Card> */}


    {/* <ApprovalTracking history={data?.workLog} /> */}
  </>
}