import { BusinessPartnerService, PurchaseContractService, ReportsService } from "@exportx/shared-models-and-services";
import { Col, Row, Select, Table, TablePaginationConfig } from "antd";
import { useEffect, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../common";
import { internationalFormattedValue } from "@exportx/ui-utils";

interface PoHeaderProps {
  supplier: any,
  pcContract: any
}

export const PoHeaderReport = (props: PoHeaderProps) => {
  const { Option } = Select;
  const { supplier, pcContract } = props
  const { authContext } = useAuthState()
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);

  const [selectedContract, setSelectedContract] = useState<string>('')
  const [selectedSupplier, setSelectedSupplier] = useState<string>('')
  const [poHeaderReport, setPoHeadeeReport] = useState<any[]>([])

  const reportService = new ReportsService();

  useEffect(() => {
    getPoReport();
  }, [
    currentPage,
    pageSize,
    selectedSupplier,
    selectedContract,
  ]);

  const getPoReport = () => {
      const req = { 
        offset: (currentPage - 1) * pageSize, 
        limit: pageSize, 
        plantCode: authContext.defaultPlant,
        contract: selectedContract,
        supplierId: selectedSupplier,
      }
      reportService.getPoHeaderReport(req).then(res => {
        if(res.status){
          setPoHeadeeReport(res.data?.pcData)
          setTotal(res.data?.total)
        } else {
          AlertMessages.getSuccessMessage(res.internalMessage)
          setPoHeadeeReport([])
        }
      }).catch(err => {
        AlertMessages.getErrorMessage(err.message)
      })
    }

  const handleTableChange = (pagination: TablePaginationConfig) => {
      setCurrentPage(pagination.current || 1);
      setPageSize(pagination.pageSize || 25);
    };
  
  const handleContractChange = (value: string) => {
    setSelectedContract(value);
    setCurrentPage(1);
  };

  const handleSupplierChange = (value: string) => {
    setSelectedSupplier(value);
    setCurrentPage(1);
  };

  const columns2: any = [
    {
      title: 'PO Order',
      dataIndex: 'contractNo',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
      render: (value, record) => {
        const link = `/#/po-detail-view?pc_id=${record.pcId}`;
        return <a href={link} className="link">{value}</a>;
      },
    },
    {
      title: 'Supplier Name',
      dataIndex: 'bpName',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
    },
    {
      title: 'Total Quantity',
      dataIndex: 'totalQty',
      align: 'right',
      onCell: (record) => ({
        rowSpan: record.rowSpan,
      }),
      render: (value) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },
    {
      title: "Delivered Quantity",
      dataIndex: 'totalDelivered',
      align: 'right',
      render: (value, record) => {
        return <span>{internationalFormattedValue(value, 3)}</span>;
      },
    },
    {
      title: "Remaining Quantity",
      dataIndex: '',
      align: 'right',
      render: (value, record) => {
        let balance = Number(record.totalQty) - Number(record.totalDelivered)
        return <span>{internationalFormattedValue(balance, 3)}</span>;
      },
    },
  ]
  return (<>
        <Row gutter={[10, 10]} style={{ marginBottom: 16 }}>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Select
                placeholder= "Filter By Contract"
                style={{ width: "100%" }}
                onChange={(value) => handleContractChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedContract || null}
                allowClear
                showSearch
              >
                {pcContract?.map((rec) => {
                  return (
                    <Option value={rec.id}>{rec.contractNo}</Option>
                  );
                })}
              </Select>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
            <Select
                placeholder= "Filter By Supplier"
                style={{ width: "100%" }}
                onChange={(value) => handleSupplierChange(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={selectedSupplier || null}
                allowClear
                showSearch
              >
                {supplier?.map((rec) => {
                  return (
                    <Option value={rec.bpId}>{rec.bpName}</Option>
                  );
                })}
              </Select>
          </Col>
        </Row>
        <Table
          className="contracts-list"
          columns={columns2}
          dataSource={poHeaderReport}
          scroll={{ x: 'max-content' }}
          pagination={{
            current: currentPage,
            pageSize: pageSize, 
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
          }}
          onChange={handleTableChange}
        />
  </>)
}

export default PoHeaderReport;