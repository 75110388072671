import { DownCircleOutlined, MessageFilled, RightCircleOutlined } from '@ant-design/icons';
import {
  AdvancePreview,
  AdvanceReceiversService,
  BargeCostingsService,
  BGCostingParentReqDto,
  BPFCostingRequestIdDto,
  CmApprovalsReqDto,
  CommercialCoalCostingService,
  ContractModesEnum,
  ContractStatusEnum,
  CostingWorkLogsEnum,
  ExpenseEntryApprovalDto,
  ExpenseEntryService,
  PaymentNoticeGetDataDto,
  PaymentNoticeService,
  PaymentTypeEnum,
  PerformaCoalCostingService,
  ReferenceFeatures,
  VendorAdvService,
} from '@exportx/shared-models-and-services';
import { internationalFormattedValue, SequenceUtils } from '@exportx/ui-utils';
import { Button, Form, Card, Col, Drawer, Flex, Input, Row, Space, Table, Timeline, Tooltip, Typography } from 'antd';
import { AlertMessages, isPermissionExist, useAuthState } from '../../../common';
import { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { useCallback, useEffect, useState, useMemo } from 'react';
import PaymentNoticeChild from './payment-notice-child';
import { useNavigate } from 'react-router-dom';
import debounce from 'lodash/debounce';
import { AccountPayableCostings } from '../costing-detail-view';
import { CostingFilters } from '../costing-detail-view/costing-filters';
import CommentsPage from '../../../common/comments/comments-page';

import { faCircleXmark, faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
interface IPaymentNoticePage {
  type: PaymentNoticePageEnum;
  paymentType?: PaymentNoticePageEnum;
}

export enum PaymentNoticePageEnum {
  APPROVAL = 'approval',
  CREATE = 'create',
  RELEASE = 'Release',
  RECEIVABLES = 'Receivables',
  CLOSED = 'Closed',
}

export const PaymentNoticePage = (props: IPaymentNoticePage) => {
  const { paymentType } = props;
  const [expandedIndex, setExpandedIndex] = useState([]);
  const { authContext } = useAuthState();
  const navigate = useNavigate();
  const [paymentNoticeData, setPaymentNoticeData] = useState<
    PaymentNoticeGetDataDto[]
  >([]);
  // const [paymentNoticeData, setPaymentNoticeData] = useState<any>();
  const [searchedText, setSearchedText] = useState('');
  const [type, setActiveTab] = useState<PaymentNoticePageEnum>(
    PaymentNoticePageEnum.CREATE,
  );
  const [serachInput, setSerachInput] = useState('');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [businessNo, setBusinessNo] = useState(null);
  const [costingType, setCostingType] = useState<CostingWorkLogsEnum>(null);
  const [bargeId, setBarge] = useState<string>(null);
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [costingId, setCostingId] = useState<string>('');
  const [commentsTitle, setCommentsTitle] = useState<string>('');

  const [dummyRefresh, setDummyRefresh] = useState<boolean>(false);

  const [formRef] = Form.useForm();

  const paymentNoticeService = new PaymentNoticeService();
  const perFormaService = new PerformaCoalCostingService();
  const commercialService = new CommercialCoalCostingService();
  const expenseEntryService = new ExpenseEntryService();
  const advService = new VendorAdvService();
  const advReceivableService = new AdvanceReceiversService();
  const bargeCostingService = new BargeCostingsService();

  useEffect(() => {
    if (
      type === PaymentNoticePageEnum.CREATE ||
      type === PaymentNoticePageEnum.CLOSED
    ) {
      if (
        !isPermissionExist([
          287, 329, 742, 189, 229, 249, 433, 454, 475, 496, 517, 538, 559, 580,
          601, 622, 642, 662, 682, 702, 308, 371, 412, 722,
        ])
      ) {
        return navigate('/');
      }
    } else if (type === PaymentNoticePageEnum.APPROVAL) {
      if (
        !isPermissionExist([
          288, 330, 743, 190, 230, 250, 434, 455, 476, 497, 518, 539, 560, 581,
          602, 623, 643, 663, 683, 703, 309, 372, 413, 723,
        ])
      ) {
        return navigate('/');
      }
    } else {
      if (
        !isPermissionExist([
          290, 332, 745, 192, 232, 252, 436, 457, 478, 499, 520, 541, 562, 583,
          604, 625, 645, 665, 685, 705, 311, 374, 415, 725,
        ])
      ) {
        return navigate('/');
      }
    }
  }, [type]);

  let status = ContractStatusEnum.ACTIVE;
  if (type === PaymentNoticePageEnum.CREATE) {
    status = ContractStatusEnum.DRAFT;
  } else if (type === PaymentNoticePageEnum.APPROVAL) {
    status = ContractStatusEnum.PENDING_APPROVAL;
  } else if (type === PaymentNoticePageEnum.CLOSED) {
    status = ContractStatusEnum.CLOSED;
  }

  const getParentData = () => {
    const req = new BGCostingParentReqDto();
    req.plantCode = authContext.defaultPlant;
    req.offset = (currentPage - 1) * pageSize;
    req.limit = pageSize;
    req.searchTerm = searchedText;
    req.businessNo = businessNo;
    req.bargeId = bargeId;
    req.costingType = costingType;
    req.status = status;
    if (paymentType) {
      req.paymentType = paymentType;
    }
    paymentNoticeService
      .getPaymentNotice(req)
      .then((res) => {
        if (res.status) {
          const filteredData = res.data?.paymentNotices?.filter((item) => {
            return hasPermission(item);
          });
          setPaymentNoticeData(filteredData ? filteredData : []);
          // setPaymentNoticeData(res.data ? res.data : []);
          setTotal(res.data?.total);
          setExpandedIndex([]);
          setDummyRefresh(false);
        } else {
          setPaymentNoticeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setPaymentNoticeData([]);
        setTotal(0);
      });
  };

  const debouncedSearchHandler = useMemo(
    () =>
      debounce((searchValue: string) => {
        setSearchedText(searchValue);
        setCurrentPage(1);
      }, 500),
    [],
  );

  useEffect(() => {
    getParentData();
  }, [
    searchedText,
    type,
    paymentType,
    currentPage,
    pageSize,
    businessNo,
    bargeId,
    costingType,
    dummyRefresh,
  ]);

  const hasPermission = (data) => {
    let permission = false;
    if (
      type === PaymentNoticePageEnum.CREATE ||
      type === PaymentNoticePageEnum.CLOSED
    ) {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([287, 329]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([742]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([189, 229, 249]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          433, 454, 475, 496, 517, 538, 559, 580, 601, 622, 642, 662, 682, 702,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([308]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([371]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([412]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([722]);
      } else {
        permission = isPermissionExist([1]);
      }
    } else if (type === PaymentNoticePageEnum.APPROVAL) {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([288, 330]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([743]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([190, 230, 250]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          434, 455, 476, 497, 518, 539, 560, 581, 602, 623, 643, 663, 683, 703,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([309]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([372]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([413]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([723]);
      } else {
        permission = isPermissionExist([1]);
      }
    } else {
      if (data.expenseType === 'Performa PC Costing') {
        permission = isPermissionExist([290, 332]);
      } else if (data.expenseType === 'Demurrage') {
        permission = isPermissionExist([745]);
      } else if (data.expenseType === 'Advance Invoice') {
        permission = isPermissionExist([192, 232, 252]);
      } else if (data.expenseType === 'Expense Entry') {
        permission = isPermissionExist([
          436, 457, 478, 499, 520, 541, 562, 583, 604, 625, 645, 665, 685, 705,
        ]);
      } else if (data.expenseType === 'Commercial PC Costing') {
        permission = isPermissionExist([311]);
      } else if (data.expenseType === 'Procurement Fees') {
        permission = isPermissionExist([374]);
      } else if (data.expenseType === 'BARGE COSTINGS') {
        permission = isPermissionExist([415]);
      } else if (data.expenseType === 'DEAD FREIGHT') {
        permission = isPermissionExist([725]);
      } else {
        permission = isPermissionExist([1]);
      }
    }
    if (permission) {
      return data;
    }
  };

  const routes = (key) => {
    let object = {
      [CostingWorkLogsEnum.PERFORMA_PC_COSTING]: `performa-pc-detail-view`,
      [CostingWorkLogsEnum.PERFORMA_SC_COSTING]: `performa-sc-detail-view`,
      [CostingWorkLogsEnum.COMMERCIAL_PC_COSTING]: `commercial-pc-detail-view`,
      [CostingWorkLogsEnum.COMMERCIAL_SC_COSTING]: `commercial-sc-detail-view`,
      [CostingWorkLogsEnum.PROCUREMENT_FEES]: `procurement-fee-detail-view`,
      [CostingWorkLogsEnum.MARKETING_FEES]: `marketing-fee-detail-view`,
      [CostingWorkLogsEnum.DEMURRAGE]: `demurrage-detail-view`,
      [CostingWorkLogsEnum.DEAD_FREIGHT]: `dead-freight`,
      [CostingWorkLogsEnum.ADVANCE_INVOICE]: `advanceDetailView`,
      [CostingWorkLogsEnum.EXPENSE_ENTRY]: 'expense-entry-detail-view',
      [CostingWorkLogsEnum.ADVANCE_RECEIVABLES]:
        'advance-receivables-detailed-view',
      [CostingWorkLogsEnum.BARGE_COSTING]: `barge-costing-detail-view`,
      [CostingWorkLogsEnum.DISPATCH]: `demurrage-detail-view`,
    };

    let id = '?costing_id=';

    if (key === ReferenceFeatures.ADVANCE_INVOICE) {
      id = '?id=';
    } else if (key === ReferenceFeatures.ADVANCE_RECEIVABLES) {
      id = '?id=';
    }

    return object[key] + id;
  };

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  // Handle search input change
  const handleSearch = (value: string) => {
    setSerachInput(value);
    debouncedSearchHandler(value);
  };

  const handleBusinessNoChange = (value: string) => {
    setBusinessNo(value);
    setCurrentPage(1);
  };

  const handleCostingTypeChange = (value: CostingWorkLogsEnum) => {
    setCostingType(value);
    setCurrentPage(1);
  };

  const bargeChangeHandler = (value: string) => {
    setBarge(value);
    setCurrentPage(1);
  };

  const commentsData = (title) => {
    setCommentsVisible(true);
    setCommentsTitle(title);
  };

  const handleCommentsClick = (record) => {
    commentsData('All Comments');
    setCostingId(record.costingId);
    setFeaturesRefName(record.expenseType === 'Demurrage' || record.expenseType === 'Dispatch' ? ReferenceFeatures.MV_DEMURRAGE : record.expenseType);
  }

  const tableColumns: ColumnsType<any> = [
    {
      title: 'Expense Reference',
      dataIndex: 'costingNo',
      render: (value, record) => {
        return (
          <>
            <a
              href={`/#/${routes(record.expenseType) + record.costingId}`}
              className="link"
            >
              {value}
            </a>
          </>
        );
      },
    },
    {
      title: 'Expense Type',
      dataIndex: 'expenseType',
      // filteredValue: [String(searchedText).toLowerCase()],
      onFilter: (value, record) => {
        return SequenceUtils.globalFilter(value, record);
      },
    },
    {
      title: 'Vendor',
      dataIndex: 'bpName',
    },

    {
      title: 'Business Number',
      dataIndex: 'businessNo',
      render: (value, row: any, index) => {
        const bnValues = [
          ...new Map(
            row.paymentNoticeBusinessNos?.map((rec) => [rec.businessNo, rec]),
          ).values(),
        ];
        return (
          <>
            {new Set(
              row.paymentNoticeBusinessNos?.filter(
                (rec) => rec.businessNo !== null || rec.businessNo != undefined,
              ),
            ).size
              ? bnValues.map(
                (
                  rec: {
                    businessNo: string;
                    businessNoName: string;
                    bnId: string;
                  },
                  index,
                ) => {
                  const comma = index !== bnValues.length - 1 ? ' , ' : '';
                  const link = `/#/bn-detail-view?bn_Id=${rec.bnId}`;
                  return (
                    <>
                      {
                        <a key={rec.bnId} href={link} className="link">
                          {rec.businessNo +
                            ` (${rec.businessNoName})` +
                            comma}
                        </a>
                      }
                    </>
                  );
                },
              )
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Barge',
      dataIndex: 'bargeNo',
      render: (value, row: any, index) => {
        const bg = [];
        row.paymentNoticeBarges?.forEach((rec) => {
          if (rec.bargeNo)
            bg.push({
              bargeNo: rec.bargeNo,
              bargeId: rec.bargeId,
              bargeNomination: rec.bargeNomination,
            });
        });
        return (
          <>
            {new Set(
              row.paymentNoticeBarges?.filter(
                (rec) => rec.bargeNo !== null || rec.bargeNo !== undefined,
              ),
            ).size
              ? bg.map((rec, index) => {
                const comma = index !== bg.length - 1 ? ' , ' : '';
                const link = `/#/barge-detail-view?barge_id=${rec?.bargeId}`;
                return (
                  <a href={`${link}`} className="link" key={rec?.bargeId}>
                    {SequenceUtils.formatNumberToSpecificLength(
                      rec?.bargeNo,
                    ) +
                      ` (${rec.bargeNomination})` +
                      comma}
                  </a>
                );
              })
              : 'NA'}
          </>
        );
      },
    },
    {
      title: 'Total',
      dataIndex: 'totalAmount',
      align: 'right',
      render: (value, record) => {
        return (
          <>
            <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
          </>
        );
      },
    },
    {
      title: '',
      align: 'center',
      width: 50,
      render: (value, record) => {
        return (
          <>
            <Tooltip placement="top" title="Comments">
              <MessageFilled
                className="message-icon"
                onClick={() => handleCommentsClick(record)}
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  const setIndex = (expanded, record) => {
    const expandedRows = [];
    if (expanded) {
      expandedRows.push(record?.costingId);
      setExpandedIndex(expandedRows);
    } else {
      setExpandedIndex(expandedRows);
    }
  };

  const rejectedHandler = (costingType, costingId, status: ContractStatusEnum) => {
    let contractType;

    if (
      costingType == CostingWorkLogsEnum.PERFORMA_PC_COSTING ||
      costingType == CostingWorkLogsEnum.COMMERCIAL_PC_COSTING
    ) {
      contractType = ContractModesEnum.PURCHASE;
    }
    if (
      costingType == CostingWorkLogsEnum.PERFORMA_SC_COSTING ||
      costingType == CostingWorkLogsEnum.COMMERCIAL_SC_COSTING
    ) {
      contractType = ContractModesEnum.SALES;
    }
    if (costingType == CostingWorkLogsEnum.PROCUREMENT_FEES) {
      contractType = CostingWorkLogsEnum.PROCUREMENT_FEES;
    }
    if (costingType == CostingWorkLogsEnum.MARKETING_FEES) {
      contractType = CostingWorkLogsEnum.MARKETING_FEES;
    }
    if (costingType == CostingWorkLogsEnum.SALES_FREIGHT_COSTING) {
      contractType = ContractModesEnum.FREIGHT_COSTING;
    }



    if (
      costingType === CostingWorkLogsEnum.BARGE_COSTING ||
      costingType === ReferenceFeatures.BARGE_COSTINGS
    ) {
      bpfStatusRejected(costingId, status);
    } else if (
      costingType === CostingWorkLogsEnum.PERFORMA_PC_COSTING ||
      costingType === CostingWorkLogsEnum.PERFORMA_SC_COSTING ||
      costingType === CostingWorkLogsEnum.SALES_FREIGHT_COSTING
    ) {
      performaApprovalOperations(costingId, costingType, contractType, status);
    } else if (
      costingType === CostingWorkLogsEnum.COMMERCIAL_PC_COSTING ||
      costingType === CostingWorkLogsEnum.COMMERCIAL_SC_COSTING ||
      costingType === CostingWorkLogsEnum.PROCUREMENT_FEES ||
      costingType === CostingWorkLogsEnum.MARKETING_FEES
    ) {
      commercialApprovalOperations(costingId, costingType, contractType, status);
    } else if (costingType === CostingWorkLogsEnum.EXPENSE_ENTRY) {
      approveExpenses(costingId, status);
    } else if (costingType === CostingWorkLogsEnum.ADVANCE_INVOICE || costingType === CostingWorkLogsEnum.ADVANCE_RECEIVABLES) {
      advanceApprovalOperations(costingId, status);

    } else if (costingType === CostingWorkLogsEnum.DEMURRAGE || costingType === CostingWorkLogsEnum.DISPATCH) {
      approveDemurrage(costingId, status);
    } else if (costingType === CostingWorkLogsEnum.DEAD_FREIGHT) {
      deadFreightApproval(costingId, status)
    }
  };

  const advanceReceivableApproveOrReject = (
    costingId: string,
    status: string,
  ) => {
    const req = new AdvancePreview(
      costingId,
      authContext.defaultPlant,
      authContext.user.userName,
      status,
    );

    advReceivableService
      .advanceReceivableApproveOrReject(req)
      .then((res) => {
        if (res.status) {
          AlertMessages.getSuccessMessage(res.internalMessage);
          setDummyRefresh(true)
          formRef.resetFields();
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
        }
      })
      .catch((err) => {
        AlertMessages.getErrorMessage(err.message);
      });
  };

  const advanceApprovalOperations = (costingId: string, status: string) => {
    formRef.validateFields().then((values) => {
      const req = new AdvancePreview(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        undefined,
        values.remarks,
      );
      advService
        .advanceApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          AlertMessages.getErrorMessage(err.message);
        });
    });

  };

  const bpfStatusRejected = (costingId, status) => {
    formRef.validateFields().then((values) => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
        costingId,
        values['remarks'],
        status,
        authContext.user.userName,
        authContext.defaultPlant,
      );
      bargeCostingService
        .bpfStatusRejected(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };
  const deadFreightApproval = (costingId, status) => {
    formRef.validateFields().then((values) => {
      const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
        costingId,
        values['remarks'],
        status,
        authContext.user.userName,
        authContext.defaultPlant,
      );
      bargeCostingService
        .deadFreightApproval2(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true)
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const performaApprovalOperations = (costingId, costingType, contractType, status) => {
    formRef.validateFields().then((values) => {
      const req = new CmApprovalsReqDto(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        costingType,
        contractType,
        values.remarks,
      );
      perFormaService
        .performaApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };

  const commercialApprovalOperations = (costingId, costingType, contractType, status) => {
    formRef.validateFields().then((values) => {
      const req = new CmApprovalsReqDto(
        costingId,
        authContext.defaultPlant,
        authContext.user.userName,
        status,
        costingType,
        contractType,
        values.remarks,
      );
      commercialService
        .commercialApprovalOperations(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    });
  };
  const approveExpenses = (expenseId: string, status: ContractStatusEnum) => {
    formRef.validateFields().then((values) => {
      const req = new ExpenseEntryApprovalDto();
      req.expenseEntryId = expenseId;
      req.plantCode = authContext.defaultPlant;
      req.status = status;
      req.approvalLevel = ContractStatusEnum.AUDIT_APPROVAL;
      req.userName = authContext.user.userName;
      req.remarks = values['remarks'];
      expenseEntryService
        .approveExpense(req)
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setDummyRefresh(true);
            formRef.resetFields();
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        })
        .catch((err) => {
          console.log('err : ', err);
        });
    });
  };

  const approveDemurrage = (costingId: string, status: ContractStatusEnum) => {
    formRef
      .validateFields()
      .then((values) => {
        const req: BPFCostingRequestIdDto = new BPFCostingRequestIdDto(
          costingId,
          values['remarks'],
          status,
          authContext.user.userName,
          authContext.defaultPlant,
        );
        bargeCostingService
          .demurrageApproval2(req)
          .then((res) => {
            if (res.status) {
              AlertMessages.getSuccessMessage(res.internalMessage);
              formRef.resetFields();
              setDummyRefresh(true);
            } else {
              AlertMessages.getErrorMessage(res.internalMessage);
            }
          })
          .catch((err) => {
            console.log(err.message);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const renderItems = (
    record: PaymentNoticeGetDataDto,
    index,
    indent,
    expanded,
  ) => {
    const remainingAmount =
      Number(record?.totalAmount) - Number(record?.transactionalTotalAmount);

    const { workLog = [], paymentNoticeTransactions = [] } = record;
    const timeLines = [];
    record.workLog?.forEach((rec) => {
      timeLines.push({
        color: 'green',
        children: (
          <>
            <Typography style={{ fontSize: 10 }}> {rec?.approvalLevel ?? rec?.actionType} By <b>{rec?.createdUser}</b>  On <b>{rec?.createdAt ? moment(rec?.createdAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
            <pre>{rec.workLog}</pre>
          </>
        )

      });
    });
    return (
      <div style={{ backgroundColor: '#D8E3EF', padding: '10px' }}>
        <AccountPayableCostings
          costingId={record.costingId}
          costingType={record.expenseType}
          remainingAmount={remainingAmount}
        >
          {(type === PaymentNoticePageEnum.CREATE && !paymentNoticeTransactions?.some(trans => trans.status === ContractStatusEnum.CLOSED)) &&
            <Card
              bordered={false}
              className="card-shadow mb-2"
              styles={{ body: { padding: '0px 10px 15px' } }}
            >
              <Form
                form={formRef}
                // initialValues={initialValues}
                layout="vertical"
                autoComplete="off"
              >
                <Row className="pt-2">
                  <Col span={16}>
                    <Form.Item
                      label="remarks"
                      name="remarks"
                      rules={[
                        {
                          required: true,
                          message: 'Enter Remarks', // fm({ id: "common.kycForm.reqBankName", defaultMessage: 'Enter Bank Name' })
                        },
                      ]}
                    >
                      <Input.TextArea placeholder="Notes" onChange={() => { }} />
                    </Form.Item>
                  </Col>
                </Row>
                {/* <br />
              <Row className="pt-2">
                <Col span={16}>
                  <Timeline
                    items={timeLines}
                  />
                </Col>
              </Row> */}
                <Flex align="middle" justify="flex-end">

                  <Button
                    size="small"
                    className="reject"
                    onClick={() => {
                      rejectedHandler(record.expenseType, record.costingId, ContractStatusEnum.CANCELLED);
                    }}
                  >
                    <FontAwesomeIcon icon={faCircleXmark} />
                    Reject
                  </Button>

                </Flex>
              </Form>
            </Card>
          }

          <PaymentNoticeChild
            paymentType={paymentType}
            record={record}
            type={type}
            getParentData={getParentData}
            status={status}
          />
        </AccountPayableCostings>
      </div>
    );
  };

  const tabListNoTitle = [
    {
      key: PaymentNoticePageEnum.CREATE,
      label: 'Creation',
    },
    {
      key: PaymentNoticePageEnum.APPROVAL,
      label: 'Approval',
    },
    {
      key: PaymentNoticePageEnum.RELEASE,
      label: 'Release',
    },
    {
      key: PaymentNoticePageEnum.CLOSED,
      label: 'Closed',
    },
  ];

  const onTab2Change = (key: PaymentNoticePageEnum) => {
    setActiveTab(key);
    setCurrentPage(1);
  };

  return (
    <Card className="card-radius">
      <Card
        className="no-shadow"
        tabList={tabListNoTitle}
        bordered={false}
        activeTabKey={type}
        size="small"
        onTabChange={onTab2Change}
        tabProps={{
          size: 'middle',
          tabBarStyle: { width: '100%' },
        }}
      >
        <CostingFilters
          handleSearch={handleSearch}
          serachInput={serachInput}
          handleBusinessNoChange={handleBusinessNoChange}
          businessNo={businessNo}
          handleCostingTypeChange={handleCostingTypeChange}
          costingType={costingType}
          bargeChangeHandler={bargeChangeHandler}
          bargeId={bargeId}
          paymentType={PaymentTypeEnum.PAYABLE}
        />
        <Table
          size="small"
          className="contracts-list"
          rowKey={(record) => record.costingId}
          columns={tableColumns}
          dataSource={paymentNoticeData}
          expandable={{
            expandedRowRender: renderItems,
            expandedRowKeys: expandedIndex,
            onExpand: setIndex,
            fixed: 'right',
          }}
          rootClassName="table-row-white"
          expandIcon={({ expanded, onExpand, record }) =>
            expanded ? (
              <DownCircleOutlined
                twoToneColor="#0083C9"
                onClick={(e) => onExpand(record, e)}
              >
                Collapse
              </DownCircleOutlined>
            ) : (
              <RightCircleOutlined
                twoToneColor="#0083C9"
                onClick={(e) => onExpand(record, e)}
              >
                Expand
              </RightCircleOutlined>
            )
          }
          scroll={{ x: 500 }}
          pagination={{
            current: currentPage,
            pageSize: pageSize,
            total: total,
            showSizeChanger: true,
            showTotal: (total) => `Total ${total} items`,
            size: 'small',
          }}
          onChange={handleTableChange}
        />
      </Card>
      <Drawer
        bodyStyle={{ paddingBottom: 0 }}
        width={700}
        onClose={() => setCommentsVisible(false)}
        open={commentsVisible}
        closable={false}
      >
        <CommentsPage
          featuresRefNo={costingId}
          featuresRefName={featuresRefName}
          commentsTitle={commentsTitle}
          setCommentsVisible={setCommentsVisible}
        />
      </Drawer>
    </Card>
  );
};

export default PaymentNoticePage;
function setDummyRefresh(arg0: boolean) {
  throw new Error('Function not implemented.');
}

