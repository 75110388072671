import { SequenceUtils } from "@exportx/ui-utils"
import { Table } from "antd"

interface PoPerformanceProps {
  boBlBarges: any[]
}

export const PoPerformanceNoBlBargeList = (props: PoPerformanceProps) => {
  
  const { boBlBarges } = props

  const transformedData = boBlBarges.flatMap((item) => {
    let barges = [];
    if (!item.barges) return [];

    item.barges?.split(" - ").map((bargeEntry) => {
      const [bargeNo, bargeName, bargeId] = bargeEntry.split(",");
      barges.push({
        bargeNo: bargeNo?.trim() || "",
        bargeName: bargeName?.trim() || "",
        bargeId: bargeId?.trim() || "",
      });
    });
    return barges;
  }
);


  const columns: any = [
    {
      title: 'Barge NO',
      dataIndex: '',
      render: (value, record) => {
        return <>
          <a href={`/#/barge-detail-view?barge_id=${record?.bargeId}`} className="link">
            {SequenceUtils.formatNumberToSpecificLength(record?.bargeNo)} ({record?.bargeName})
          </a>
        </>
      }
    },
  ]


  return (
    <>
      <Table
        className="contracts-list"
        columns={columns}
        dataSource={transformedData}
        rowClassName={"main-row"}
      />
    </>
  )
}

export default PoPerformanceNoBlBargeList;