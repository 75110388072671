import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { AdvanceEnumType, AdvanceTypeReqDto, CostingTypesEnum, CurrencyConverterService, CurrencyExchangeRequestDto } from "@exportx/shared-models-and-services";
import { SequenceUtils, getLocalFormat } from "@exportx/ui-utils";
import { Card, Form, FormInstance, InputNumber, Popover, Select, Table, Tooltip, Typography } from "antd";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { AlertMessages, useAuthState } from "../../../../common";
import DatePicker from "../../../../common/data-picker/date-picker";


interface IPriceCalculationForm {
    priceCalculationData: any[];
    costingType: CostingTypesEnum;
    incoterm: string;
    setPriceCalculationData: React.Dispatch<React.SetStateAction<any[]>>;
    setSelectedRowsData: React.Dispatch<React.SetStateAction<any[]>>;
    formRef: FormInstance<any>;
    isItEdit?: boolean;
    calcUpdateData?: any[];
    getInvoiceContracts: (req: AdvanceTypeReqDto, incoterm: string) => void;
    setSelectedRows?: React.Dispatch<React.SetStateAction<any[]>>;
}

export const PriceCalculationForm = (props: IPriceCalculationForm) => {
    const { authContext } = useAuthState();
    const { Option } = Select;
    const { setSelectedRows, getInvoiceContracts, priceCalculationData, setPriceCalculationData, setSelectedRowsData, costingType, incoterm, formRef, isItEdit, calcUpdateData } = props;
    const [selectedRowKeysData, setSelectedRowKeysData] = useState<string[]>([]);
    const tableRef = useRef<any>(null);
    const [disableEditIcon, setDisableEditIcon] = useState<number>()


    useEffect(() => {
        let bgIndex: number
        if (calcUpdateData?.length) {
            bgIndex = priceCalculationData.findIndex(rec => rec.bgUId === calcUpdateData?.[0]?.bargeId);
            editHandler(bgIndex)
        };
        if (incoterm === 'FOB BARGE') {
            setTimeout(() => {
                onChangeHandler(bgIndex)
            }, 1000)
        }

    }, [calcUpdateData])


    async function editHandler(index: number) {
        if (incoterm === 'FOB BARGE') {
            if (priceCalculationData && priceCalculationData.length) {
                formRef.setFieldValue(['costingTransactions', index, 'quantityInMt'], Number(calcUpdateData[0]?.inWardQty));
                formRef.setFieldValue(['costingTransactions', index, 'coalBasePrice'], Number(calcUpdateData[0]?.coalPricePerMt));
                if(calcUpdateData[0]?.exchangeDate) {
                    formRef.setFieldValue(['costingTransactions', index, 'exchangeDate'], moment(calcUpdateData[0]?.exchangeDate));
                }
                formRef.setFieldValue(['costingTransactions', index, 'currencyType'], calcUpdateData[0]?.currencyType);
                formRef.setFieldValue(['costingTransactions', index, 'exchangeRateAdjustment'], calcUpdateData[0]?.exchangeRateAdjustment);
                formRef.setFieldValue(['costingTransactions', index, 'exchangeRate'], Number(calcUpdateData[0]?.exchangeRate ?? 1));
                formRef.setFieldValue(['costingTransactions', index, 'displayExchangeRate'], Number(calcUpdateData[0]?.exchangeRate ?? 1));
                await exchangeDateOnChangeHandler(index, formRef.getFieldValue('costingTransactions')[index]?.exchangeDate);
                onChangeHandler(index);
                onSelectSelectedData([{ ...priceCalculationData[0], quantityInMt: calcUpdateData[0].inWardQty, exchangeDate: calcUpdateData[0].exchangeDate }]);
            }

        } else {
            if (priceCalculationData && priceCalculationData.length) {
                // formRef.setFieldValue('costingTransactions', [{ ...priceCalculationData[0], quantityInMt: calcUpdateData[0].inWardQty, exchangeDate: calcUpdateData[0]?.exchangeDate }])
                formRef.setFieldValue(['costingTransactions', 0, 'quantityInMt'], Number(calcUpdateData[0]?.inWardQty));
                if(calcUpdateData[0]?.exchangeDate) {
                    formRef.setFieldValue(['costingTransactions', 0, 'exchangeDate'], moment(calcUpdateData[0]?.exchangeDate));
                }
                formRef.setFieldValue(['costingTransactions', 0, 'currencyType'], calcUpdateData[0]?.currencyType);
                formRef.setFieldValue(['costingTransactions', 0, 'exchangeRateAdjustment'], calcUpdateData[0]?.exchangeRateAdjustment);
                formRef.setFieldValue(['costingTransactions', 0, 'exchangeRate'], Number(calcUpdateData[0]?.exchangeRate ?? 1));
                formRef.setFieldValue(['costingTransactions', 0, 'displayExchangeRate'], Number(calcUpdateData[0]?.exchangeRate ?? 1));
                await exchangeDateOnChangeHandler(0, formRef.getFieldValue('costingTransactions')[0]?.exchangeDate);
                onChangeHandler(0);
                onSelectSelectedData([{ ...priceCalculationData[0], quantityInMt: calcUpdateData[0].inWardQty, exchangeDate: calcUpdateData[0].exchangeDate }]);
            }
        }

    }

    const getCheckboxProps = (record: any) => {
        if (record.quoteCurrency !== authContext.defaultPlantCurrency) {
            return { disabled: true };
        }
        return {};
    };


    const checkDisability = (rowData, index, isBargeMapped: boolean) => {
        if (index === disableEditIcon) {
            rowData.exchangeDate = rowData.exchangeDate;
        } else {
            rowData.exchangeDate = undefined
        }
        if (rowData.quoteCurrency !== null && rowData.quoteCurrency !== authContext.defaultPlantCurrency) {
            if (rowData.exchangeDate) {
                return 'Editable-Icon'
            } else {
                return 'disabled-icon'
            }
        } else {
            if (rowData.quoteCurrency === null && rowData.baseCurrency == authContext.defaultPlantCurrency) {
                return 'Editable-Icon'
            } else {
                if (rowData.exchangeDate && rowData.exchangeRate != 1) {
                    return 'Editable-Icon'
                } else {
                    return 'disabled-icon'
                }
            }

        }
    }
    const onChangeHandler = (index: number) => {

        const quantityInMt = Number(formRef.getFieldValue(['costingTransactions', index, 'quantityInMt']));
        const basePrice = Number(formRef.getFieldValue(['costingTransactions', index, 'coalBasePrice']));
        let exchangeRate = formRef.getFieldValue(['costingTransactions', index, 'exchangeRate']);
        let exchangeDate = formRef.getFieldValue(['costingTransactions', index, 'exchangeDate']);
        let displayExchangeRate = formRef.getFieldValue(['costingTransactions', index, 'displayExchangeRate']);
        onChangeFormValuesSetter(index, quantityInMt, basePrice, Number(exchangeRate ?? 1), exchangeDate, Number(displayExchangeRate ?? 1), 2);
    }

    const exchangeDateOnChangeHandler = async (index: number, e) => {
        const quantityInMt = Number(formRef.getFieldValue(['costingTransactions', index, 'quantityInMt']));
        const basePrice = Number(formRef.getFieldValue(['costingTransactions', index, 'coalBasePrice']));
        const exchangeDate = formRef.getFieldValue(['costingTransactions', index, 'exchangeDate']);
        let exchangeRate = formRef.getFieldValue(['costingTransactions', index, 'exchangeRate']);
        let displayExchangeRate = formRef.getFieldValue(['costingTransactions', index, 'displayExchangeRate']);
        let currencyType = formRef.getFieldValue(['costingTransactions', index, 'currencyType']);
        let exchangeRateAdjustment = formRef.getFieldValue(['costingTransactions', index, 'exchangeRateAdjustment']);
        priceCalculationData[index].currencyType = currencyType;
        priceCalculationData[index].exchangeRateAdjustment = exchangeRateAdjustment;
        const empty = [];
        if (exchangeDate && currencyType) {
            const exchangeRateRes = await getCurrencyExchange(priceCalculationData[index].baseCurrency, authContext.defaultPlantCurrency, exchangeDate, currencyType, exchangeRateAdjustment);
            if (!exchangeRateRes) {
                return;
            } else {
                priceCalculationData[index].purchaseRate = Number(exchangeRateRes.purchaseRate)
                priceCalculationData[index].sellingRate = Number(exchangeRateRes.sellingRate)
                exchangeRate = Number(exchangeRateRes.exchangeRate ?? 1)
                displayExchangeRate = Number(exchangeRateRes.displayExchangeRate ?? 1);
            }
        } else {
            exchangeRate = 1;
            displayExchangeRate = 1;
        }
        empty.push(exchangeDate, exchangeRate, displayExchangeRate)
        if (e) {
            formRef.getFieldValue('costingTransactions').forEach((rec, fieldsIndex) => {
                formRef.setFieldValue(['costingTransactions', fieldsIndex, 'exchangeDate'], undefined);
                formRef.setFieldValue(['costingTransactions', fieldsIndex, 'exchangeRate'], undefined);
                formRef.setFieldValue(['costingTransactions', fieldsIndex, 'displayExchangeRate'], undefined);
            })
            formRef.setFieldValue(['costingTransactions', index, 'exchangeDate'], empty[0]);
            formRef.setFieldValue(['costingTransactions', index, 'exchangeRate'], empty[1]);
            formRef.setFieldValue(['costingTransactions', index, 'displayExchangeRate'], empty[2]);
        }
        onChangeFormValuesSetter(index, quantityInMt, basePrice, exchangeRate, exchangeDate, Number(displayExchangeRate), 1);
        setDisableEditIcon(e ? index : undefined)
    }
    const onChangeFormValuesSetter = (index: number, quantityInMt: number, basePrice: number, exchangeRate: any, exchangeDate: any, displayExchangeRate: number, n) => {
        setPriceCalculationData(prevState => {
            const prevStateIndexData = { ...prevState[index] };
            if (exchangeDate) {
                formRef.setFieldValue(['costingTransactions', index, 'exchangeRate'], exchangeRate.toFixed(8));
                formRef.setFieldValue(['costingTransactions', index, 'displayExchangeRate'], Number(displayExchangeRate).toFixed(8));
                formRef.setFieldValue(['costingTransactions', index, 'exchangeDate'], moment(exchangeDate));
                prevStateIndexData.exchangeDate = exchangeDate;
                prevStateIndexData.exchangeRate = exchangeRate;
                prevStateIndexData['displayExchangeRate'] = displayExchangeRate;
                prevStateIndexData.quoteCurrency = authContext.defaultPlantCurrency;
            }
            else {
                formRef.setFieldValue(['costingTransactions', index, 'exchangeRate'], undefined);
                formRef.setFieldValue(['costingTransactions', index, 'displayExchangeRate'], undefined);
                formRef.setFieldValue(['costingTransactions', index, 'exchangeDate'], undefined);
                prevStateIndexData.exchangeDate = undefined;
                prevStateIndexData.exchangeRate = undefined;
                prevStateIndexData['displayExchangeRate'] = undefined;
                prevStateIndexData.quoteCurrency = undefined;


            }
            prevStateIndexData.quantityInMt = (!Number.isNaN(quantityInMt)) ? quantityInMt : prevStateIndexData.quantityInMt;
            prevStateIndexData.quoteCurrency = authContext.defaultPlantCurrency;
            let total = basePrice * quantityInMt * exchangeRate;
            prevStateIndexData.total = total;
            prevStateIndexData.priceAfterExchange = basePrice * exchangeRate;
            const freightPricePmt = prevStateIndexData.freightPricePmt ?? 0;
            prevStateIndexData.freightAfterExchange = freightPricePmt * exchangeRate;
            prevStateIndexData.freightTotal = freightPricePmt * quantityInMt * exchangeRate;
            prevState[index] = prevStateIndexData
            setSelectedRowsData([prevStateIndexData])
            return [
                ...prevState,
            ]
        });
    }

    const getCurrencyExchange = async (billingCurrency: string, negotiationCurrency: string, exchangeDate: string, currencyType: string, exchangeRateAdjustment: string) => {
        try {
            const currencyConverterService = new CurrencyConverterService();
            const req = new CurrencyExchangeRequestDto(billingCurrency, negotiationCurrency, moment(exchangeDate).format('YYYY-MM-DD'), currencyType, exchangeRateAdjustment)
            if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
                return;
            }
            const res = await currencyConverterService.getCurrencyExchangeRate(req);
            if (!res.status) {
                AlertMessages.getErrorMessage(res.internalMessage);
                return;
            } else {
                return res.data;
            }
        } catch (error: any) {
            AlertMessages.getErrorMessage(error.message);
        }
    }

    const rowSelection = {
        columnTitle: selectedRowKeysData.length > 0 ? <div></div> : <></>,
        onChange: (selectedRowKeys, selectedRows, changeRows) => {
            setSelectedRowKeysData(selectedRowKeys);
            setSelectedRows(selectedRowKeys);
            onSelectSelectedData(selectedRows);
            const req = new AdvanceTypeReqDto(authContext.defaultPlant, AdvanceEnumType.Against_Performa_Invoice, formRef.getFieldValue('contractId'), selectedRowKeys, formRef.getFieldValue('purchaseType'), formRef.getFieldValue('qualityId'), selectedRows[0]?.coalSupplierId)
            getInvoiceContracts(req, selectedRows[0]?.incoterm);
        },
        selectedRowKeys: selectedRowKeysData,
        getCheckboxProps,
    };

    const onSelectSelectedData = (selectedRowsData: any[]) => {
        if (selectedRowsData.length && selectedRowsData[0] != undefined) {
            setSelectedRowsData(selectedRowsData);
        } else {
            setSelectedRowsData([])
        }
    };


    // for in case the costing have mapped barge at update time we want to visible the field
    const disableValueForDate = (index, rowData): boolean => {
        if (!calcUpdateData.length) {
            return rowData.isBargeMapped
        } else if (calcUpdateData.length) {
            if (calcUpdateData?.some(rec => rec.bargeId === rowData.bgUId)) {
                return false
            } else {
                return rowData.isBargeMapped
            }
        }
    }



    const tableColumns1: any[] = [];


    if (costingType === CostingTypesEnum.PERFORMA || costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
        tableColumns1.push({
            render: (text, rowData, index) => (
                <span >
                    <Tooltip placement="topRight" title="Edit">
                        <EditOutlined ref={tableRef} type="edit"
                            onClick={() => { onSelectSelectedData([rowData]) }}
                            style={{ color: '#1890ff', fontSize: '20px' }}
                            className={checkDisability(rowData, index, rowData.isBargeMapped)}
                        />
                    </Tooltip>
                </span>
            )
        });
        if (incoterm === 'FOB BARGE') {
            tableColumns1.push(
                {
                    title: 'Barge #',
                    dataIndex: 'bargeId',
                    render: (text, rowData) => (
                        <span>
                            {`${SequenceUtils.formatNumberToSpecificLength(rowData.bargeId)} ${rowData.bargeNomination ? ` - ${rowData.bargeNomination}` : ''}`}
                        </span>
                    )
                }
            )
        }
    } else {
        tableColumns1.push(
            {
                title: 'Barge #',
                dataIndex: 'bargeId',
                render: (text, rowData) => (
                    <span>
                        {`${SequenceUtils.formatNumberToSpecificLength(rowData.bargeId)} ${rowData.bargeNomination ? ` - ${rowData.bargeNomination}` : ''}`}
                    </span>
                )
            }
        )
    };


    tableColumns1.push(...[
        {
            title: 'Inco Term',
            dataIndex: 'incoterm',
        },
        {
            title: 'Qty(MT)',
            dataIndex: 'quantityInMt',
            align: 'right',
            render: (value, row, index) => {
                return (
                    <>
                        <Form.Item name={[index, "quantityInMt"]}>
                            <InputNumber onChange={() => { onChangeHandler(index) }} min={0} type="number" />
                        </Form.Item>
                    </>
                );
            }
        },

        ...(['CFR DP', 'CIF DP'].includes(incoterm) && costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) ? [{
            title: 'Freight (MT)',
            dataIndex: 'adjustedPmt',
            align: 'right',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData?.freightPricePmt), rowData?.baseCurrency)}`}
                    </span>
                )
            }
        }]:
        [
        
        {
            title: 'Coal Price(per MT)',
            dataIndex: 'coalBasePrice',
            align: 'right',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData?.coalBasePrice), rowData?.baseCurrency)}`}
                    </span>
                )
            }

        }],
        {
            title: 'Currency Type',
            dataIndex: 'currencyType',
            width: 120,
            render: (value, record, index) => {
                return (
                    <>
                        {(priceCalculationData[index]?.baseCurrency === authContext.defaultPlantCurrency) ? 
                        <>
                        <Form.Item 
                            name={[index, "currencyType"]}
                            style={{ display: 'none' }}
                        >
                            <Select>
                                <Option>Select</Option>
                            </Select>
                        </Form.Item> -- </> : 
                        <Form.Item 
                            name={[index, "currencyType"]}
                            style={{ width: '100%' }}
                        >
                            <Select
                                disabled={disableValueForDate(index, record)}
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} 
                                allowClear 
                                showSearch
                                defaultValue={!formRef.getFieldValue(['costingTransactions', index,'currencyType']) && formRef.setFieldValue(['costingTransactions', index,'currencyType'] , "JISDOR")}
                                placeholder='Select Currency'
                                onChange={(e) => { exchangeDateOnChangeHandler(index, e)}}

                            >
                                {['JISDOR', 'Middle Rate', 'Tax Rate'].map((item, index) => {
                                    return <Option value={item} key={index}>{item}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        }
                    </>
                )
            }
        },
        {
            title: 'JISDOR Date',
            dataIndex: 'exchangeDate',
            render: (value, row, index) => {

                return (
                    <>
                        {(priceCalculationData[index]?.baseCurrency === authContext.defaultPlantCurrency) ? <><Form.Item name={[index, "exchangeDate"]} style={{ display: 'none' }}>
                            <DatePicker />
                        </Form.Item> -- </> : 
                        <Form.Item name={[index, "exchangeDate"]}>
                            <DatePicker
                                // disabled={calcUpdateData.length ? false : row.isBargeMapped} 
                                disabled={disableValueForDate(index, row)}
                                onChange={(e) => { exchangeDateOnChangeHandler(index, e) }} />
                        </Form.Item>
                        }
                    </>
                );
            }
        },
        {
            title: 'Exchange Rate Adjustment',
            dataIndex: 'exchangeRateAdjustment',
            render: (value, row, index) => {
                return (
                    <>{(priceCalculationData[index]?.baseCurrency === authContext.defaultPlantCurrency) ? "--" :
                        <Form.Item name={[index, "exchangeRateAdjustment"]} >
                            <InputNumber 
                            defaultValue={0}
                            onChange={(e) => exchangeDateOnChangeHandler(index, e)}
                            />
                        </Form.Item>
                    }</>
                );
            }
        },
        {
            title: 'Exchange Rate',
            dataIndex: 'exchangeRate',
            render: (value, row, index) => {
                return (
                    <span style={{ display: 'flex' }}>
                        <Form.Item name={[index, "exchangeRate"]} hidden  >
                            <InputNumber disabled={true} />
                        </Form.Item>
                        <Form.Item name={[index, "displayExchangeRate"]} >
                            <InputNumber disabled={true} />
                        </Form.Item>
                        {formRef.getFieldValue(['costingTransactions', index, 'currencyType']) === 'Middle Rate' && (
                            <span style={{ paddingLeft: 5 }}>
                                <Popover
                                    content={
                                        <>
                                            <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{row?.purchaseRate}</b></Typography>
                                            <Typography style={{ fontSize: 10 }}>Selling Rate <b>{row?.sellingRate}</b></Typography>
                                        </>
                                    }
                                    title="Rates"
                                    placement="bottom"              
                                >
                                    <InfoCircleOutlined style={{ color: "#0295D4" }} />
                                </Popover>
                            </span>
                        )}

                    </span>
                );
            }
        }
    ]);
    if (['CFR DP', 'CIF DP'].includes(incoterm) && costingType !== CostingTypesEnum.SALE_FREIGHT_COSTING) {
        tableColumns1.push(...[{
            title: 'Freight (MT)',
            dataIndex: 'adjustedPmt',
            align: 'right',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData?.freightPricePmt), rowData?.baseCurrency)}`}
                    </span>
                )
            }
        }]);
    }

    if (costingType !== CostingTypesEnum.PERFORMA && costingType !== CostingTypesEnum.SALE_FREIGHT_COSTING) {
        tableColumns1.push(...[{
            title: 'Adjusted Coal Price(per MT)',
            dataIndex: 'adjustedCoalBasePrice',
            align: 'right',
            render: (text, rowData) => {
                return (
                    <span>
                        {`${getLocalFormat(Number(rowData.adjustedCoalBasePrice), rowData.baseCurrency)}`}
                    </span>
                )
            }
        }]);
    }

    tableColumns1.push(...[
        {
            title: 'Price after Exchange',
            dataIndex: 'priceAfterExchange',
            align: 'right',
            render: (text, record) => {
                return `${getLocalFormat(Number(costingType === CostingTypesEnum.SALE_FREIGHT_COSTING ? record.freightAfterExchange ?? 0 : record.priceAfterExchange ?? 0), authContext.defaultPlantCurrency)}`
            }
        },
        {
            title: 'Total Price',
            dataIndex: 'total',
            align: 'right',
            render: (text, record) => {
                return `${getLocalFormat(Number(costingType === CostingTypesEnum.SALE_FREIGHT_COSTING ? record.freightTotal ?? 0 : record.total ?? 0), authContext.defaultPlantCurrency)}`
            }
        }
    ])

    const getTitle = () => {
        let title = '';
        if (costingType === CostingTypesEnum.PERFORMA) {
            if (incoterm === 'FOB BARGE') {
                title = 'Select Barges for Costing'
            } else {
                title = 'Select Purchase Type for Costing'
            }
        } else if(costingType === CostingTypesEnum.SALE_FREIGHT_COSTING) {
            title = 'Select Sales Type for Costing'
        }
        else {
            title = 'Select Barges for Costing'
        }
        return title;
    }

    console.log('priceCalculationData =>', priceCalculationData)



    return (
        <Card title={getTitle()}>
            <Form autoComplete='off' form={formRef}
                initialValues={{
                    costingTransactions: priceCalculationData.map((rec, index) => {
                        let exchangeDate;
                        if (rec.exchangeDate) {
                            exchangeDate = moment.isMoment(rec.exchangeDate) ? rec.exchangeDate : moment(rec.exchangeDate);
                            return { ...rec, exchangeDate }
                        } else {
                            return { ...rec }
                        }
                    })
                }}>
                <Form.List name="costingTransactions">
                    {(particulars, { add, remove }) => {
                        return <Table
                            // className='contracts'
                            rowSelection={costingType === CostingTypesEnum.PERFORMA || costingType === CostingTypesEnum.SALE_FREIGHT_COSTING ? undefined : {
                                ...rowSelection
                            }}
                            rowKey={record => record.bgUId}
                            columns={tableColumns1}
                            dataSource={priceCalculationData}
                            pagination={false}
                            bordered={true}
                        />
                    }}
                </Form.List>
            </Form>
        </Card>
    )
}

export default PriceCalculationForm;