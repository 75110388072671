import { BargeIdRequestDto, BargeService, CalculationTableDto, CommercialGetAllDto, ContractModesEnum, ContractTermEnum, MutualAgreed, PurchaseSupplierReportDto, SamplingAndAnalysisService, ScqTypicalRequestDto } from '@exportx/shared-models-and-services';
import { Card, Col, Form, Input, InputNumber, Row, Select, Space, Table, Typography, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../common';
import { internationalFormattedValue } from '@exportx/ui-utils';

interface IProsQualityPriceAdjustments {
  selectedRowData: CalculationTableDto;
  contractType: ContractModesEnum;
  formInitialValues: CommercialGetAllDto

}

const { Option } = Select;
export const QualityPriceAdjustmentsDeatils = (props: IProsQualityPriceAdjustments) => {
  const { contractType, selectedRowData, formInitialValues } = props;
  const [bargeGridData, setBargeGridData] = useState<PurchaseSupplierReportDto[]>([]);
  const [initialValues, setInitialValues] = useState(undefined);
  const { authContext } = useAuthState();
  const qualityService = new SamplingAndAnalysisService();
  const bargeService = new BargeService();
  const [formRef] = Form.useForm();
  const [totalBonusPrice, setTotalBonusPrice] = useState<number>(0);
  const [qualityAdjustPrice, setQualityAdjustPrice] = useState<number>(0);


  useEffect(() => {
    if (contractType === ContractModesEnum.SALES) {
      getScqTypicalSpecs();
    } else {
      getPurchaseSamplingSupplier();

    }
  }, [contractType, selectedRowData?.bgUId]);

  useEffect(() => {
    if (formInitialValues) {
      formRef.setFieldValue("gcvAdjustment", formInitialValues?.gcvAdjustment)
      formRef.setFieldValue("ashPriceAdjustment", formInitialValues?.ashPriceAdjustment)
      formRef.setFieldValue("tsPriceAdjustment", formInitialValues?.tsPriceAdjustment)
      formRef.setFieldValue("totalPenalty", formInitialValues?.totalPenalty)
    }

  }, [formInitialValues])

  useEffect(() => {
    if (bargeGridData) {
      setTotalValuesToFields();
    }
  }, [bargeGridData]);

  const getScqTypicalSpecs = () => {
    const req = new ScqTypicalRequestDto(formInitialValues?.qualityId, authContext.defaultPlant, formInitialValues?.portType);
    qualityService.getSalesSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        // calculateDefaultBonusOrPenalty(res.data);
      } else {
        console.log(res.internalMessage);
        setBargeGridData([]);
      }
    }).catch(err => console.log(err.message));
  };


  const setTotalValuesToFields = () => {
    formRef.validateFields().then(values => {
      const bonusPenaltyTotal: number = Number((Number(values.tsPriceAdjustment) + Number(values.gcvAdjustment) + Number(values.ashPriceAdjustment)));
      const adjustedPrice: number = Number((Number(formInitialValues?.adjustedPriceValue)));
      formRef.setFieldValue('totalBonusPrice', bonusPenaltyTotal);
      //Contract price - (GCV Adjustment +TS Penalty cal + Ash Penalty cal)
      formRef.setFieldValue('qualityAdjustPrice', Number(adjustedPrice));
      setTotalBonusPrice(bonusPenaltyTotal);
      setQualityAdjustPrice(adjustedPrice);
    })

  }

  const rejectionHandler = (name: string, rowData: PurchaseSupplierReportDto) => {
    let disabled = true;
    let initialValue = null;
    if (name === 'gcvRejection') {
      if (Number(rowData?.cvActual) < Number(rowData?.cvRejection)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else if (name === 'ashRejection') {
      if (Number(rowData?.ashTypical) < Number(rowData?.ashActual)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    } else {
      if (Number(rowData?.tsTypical) < Number(rowData?.tsActual)) {
        // disabled = false;
        initialValue = MutualAgreed.MUTUAL_AGREED;
      }
    }

    formRef.setFieldValue(name, initialValue);
    return <Typography className="small-text">{initialValue ? initialValue : 'No Rejection'}</Typography>
      ;
  }

  const tableColumns: any = [
    {
      title: 'Typical Analysis',
      dataIndex: 'typicalAnalysis',
      width: 150,
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Basis',
      dataIndex: 'basis',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Typical',
      dataIndex: 'typical',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Rejection',
      dataIndex: 'rejection',
      width: 130,
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Bonus Cap',
      dataIndex: 'bonusCap',
      width: 130,
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Penalty',
      dataIndex: 'penalty',
      width: 130,
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Actual',
      width: 130,
      dataIndex: 'actual',
      render: (value, record) => <Typography className="small-text">{value ?? 'NA'}</Typography>

    },
    {
      title: 'Rejection',
      align: 'center',
      dataIndex: 'rejectionApplied',
      render: (name, row) => {
        return rejectionHandler(name, bargeGridData[0]);
      }
    },
    {
      title: 'Bonus/Penalty',
      width: 130,
      align: 'center',
      dataIndex: 'bonusOrPenaltyCap',
      render: (name, row) => {
        console.log('name ==>', name);
        return (
          <>
            <span className="small-text">{formRef.getFieldValue(name)}</span>
            <Form.Item name={name} style={{ display: 'none' }}>
              <InputNumber onChange={() => {
                const one = formRef.getFieldValue("gcvAdjustment")
                const two = formRef.getFieldValue("ashPriceAdjustment")
                const th = formRef.getFieldValue("tsPriceAdjustment")
                formRef.setFieldValue('totalBonusPrice', Number(one) + Number(two) + Number(th));
                setTotalValuesToFields();
              }} disabled={true} />
            </Form.Item>
          </>

        )
      }
    },
  ];

  const processTableData = (item: PurchaseSupplierReportDto, dischargeSamp: PurchaseSupplierReportDto) => {
    return [
      {
        typicalAnalysis: 'Gross Calorific Value-1',
        name: 'qualityCalorificValueActual',
        units: 'Kcal/Kg',
        basis: item?.cvBasis || dischargeSamp?.cvBasis
          ? `${item?.cvBasis || ''}${dischargeSamp?.cvBasis ? ` / ${dischargeSamp?.cvBasis}` : ''}`
          : 'NA',
        typical: item?.cvTypical || dischargeSamp?.cvTypical
          ? `${item?.cvTypical || ''}${dischargeSamp?.cvTypical ? ` / ${dischargeSamp?.cvTypical}` : ''}`
          : 'NA',
        rejection: item?.cvRejection || dischargeSamp?.cvRejection
          ? `${item?.cvRejection || ''}${dischargeSamp?.cvRejection ? ` / ${dischargeSamp?.cvRejection}` : ''}`
          : 'NA',
        bonusCap: item?.cvBonusCap || dischargeSamp?.cvBonusCap
          ? `${item?.cvBonusCap || ''}${dischargeSamp?.cvBonusCap ? ` / ${dischargeSamp?.cvBonusCap}` : ''}`
          : 'NA',
        penalty: item?.cvPenality || dischargeSamp?.cvPenality
          ? `${item?.cvPenality || ''}${dischargeSamp?.cvPenality ? ` / ${dischargeSamp?.cvPenality}` : ''}`
          : 'NA',
        actual: item?.cvActual || dischargeSamp?.cvActual
          ? `${item?.cvActual || ''}${dischargeSamp?.cvActual ? ` / ${dischargeSamp?.cvActual}` : ''}`
          : 'NA',
        rejectionApplied: "gcvRejection",
        bonusOrPenaltyCap: 'gcvAdjustment'
      },
      {
        typicalAnalysis: 'Ash',
        name: 'qualityAshActual',
        units: '%',
        basis: item?.ashBasis || dischargeSamp?.ashBasis
          ? `${item?.ashBasis || ''}${dischargeSamp?.ashBasis ? ` / ${dischargeSamp?.ashBasis}` : ''}`
          : 'NA',
        typical: item?.ashTypical || dischargeSamp?.ashTypical
          ? `${item?.ashTypical || ''}${dischargeSamp?.ashTypical ? ` / ${dischargeSamp?.ashTypical}` : ''}`
          : 'NA',
        rejection: item?.ashRejection || dischargeSamp?.ashRejection
          ? `${item?.ashRejection || ''}${dischargeSamp?.ashRejection ? ` / ${dischargeSamp?.ashRejection}` : ''}`
          : 'NA',
        penalty: item?.ashPenality || dischargeSamp?.ashPenality
          ? `${item?.ashPenality || ''}${dischargeSamp?.ashPenality ? ` / ${dischargeSamp?.ashPenality}` : ''}`
          : 'NA',
        actual: item?.ashActual || dischargeSamp?.ashActual
          ? `${item?.ashActual || ''}${dischargeSamp?.ashActual ? ` / ${dischargeSamp?.ashActual}` : ''}`
          : 'NA',
        rejectionApplied: "ashRejection",
        bonusOrPenaltyCap: 'ashPriceAdjustment'
      },
      {
        typicalAnalysis: 'Total Sulphur',
        name: 'qualityTotalSulphurActual',
        units: '%',
        basis: item?.tsBasis || dischargeSamp?.tsBasis
          ? `${item?.tsBasis || ''}${dischargeSamp?.tsBasis ? ` / ${dischargeSamp?.tsBasis}` : ''}`
          : 'NA',
        typical: item?.tsTypical || dischargeSamp?.tsTypical
          ? `${item?.tsTypical || ''}${dischargeSamp?.tsTypical ? ` / ${dischargeSamp?.tsTypical}` : ''}`
          : 'NA',
        rejection: item?.tsRejection || dischargeSamp?.tsRejection
          ? `${item?.tsRejection || ''}${dischargeSamp?.tsRejection ? ` / ${dischargeSamp?.tsRejection}` : ''}`
          : 'NA',
        penalty: item?.tsPenality || dischargeSamp?.tsPenality
          ? `${item?.tsPenality || ''}${dischargeSamp?.tsPenality ? ` / ${dischargeSamp?.tsPenality}` : ''}`
          : 'NA',
        actual: item?.tsActual || dischargeSamp?.tsActual
          ? `${item?.tsActual || ''}${dischargeSamp?.tsActual ? ` / ${dischargeSamp?.tsActual}` : ''}`
          : 'NA',
        rejectionApplied: "tsRejection",
        bonusOrPenaltyCap: 'tsPriceAdjustment'
      }
    ];
  }



  const tableData: any = [
    {
      typicalAnalysis: 'Gross Calorific Value-1 (Kcal/Kg)',
      name: 'qualityCalorificValueActual',
      units: 'Kcal/Kg',
      basis: bargeGridData[0]?.cvBasis,
      typical: bargeGridData[0]?.cvTypical,
      rejection: bargeGridData[0]?.cvRejection,
      bonusCap: bargeGridData[0]?.cvBonusCap,
      penalty: bargeGridData[0]?.cvPenality,
      actual: bargeGridData[0]?.['cvActual'],
      rejectionApplied: "gcvRejection",
      bonusOrPenaltyCap: 'gcvAdjustment'
    },
    {
      typicalAnalysis: 'Ash (%)',
      name: 'qualityAshActual',
      units: '%',
      basis: bargeGridData[0]?.ashBasis,
      typical: bargeGridData[0]?.ashTypical,
      rejection: bargeGridData[0]?.ashRejection,
      penalty: bargeGridData[0]?.ashPenality,
      actual: bargeGridData[0]?.['ashActual'],
      rejectionApplied: "ashRejection",
      bonusOrPenaltyCap: 'ashPriceAdjustment'
    },
    {
      typicalAnalysis: 'Total Sulphur (%)',
      name: 'qualityTotalSulphurActual',
      units: '%',
      basis: bargeGridData[0]?.tsBasis,
      typical: bargeGridData[0]?.tsTypical,
      rejection: bargeGridData[0]?.tsRejection,
      penalty: bargeGridData[0]?.tsPenality,
      actual: bargeGridData[0]?.['tsActual'],
      rejectionApplied: "tsRejection",
      bonusOrPenaltyCap: 'tsPriceAdjustment'
    }
  ];


  const getPurchaseSamplingSupplier = () => {
    const req = new BargeIdRequestDto(selectedRowData?.bgUId, authContext.defaultPlant);
    qualityService.getPurchaseSamplingSupplier(req).then(res => {
      if (res.status) {
        setBargeGridData(res.data);
        // calculateDefaultBonusOrPenalty(res.data);
      }
      else {
        setBargeGridData([]);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    })
  }


  return (
    <div>
      {
        bargeGridData.length !== 0 &&
        <Card
          className="card-shadow"
          styles={{ body: { padding: '0px 10px 15px' } }}
          style={{ marginBottom: 8 }}
          title={<h6>Quality Adjustment</h6>}
        >
          <Form form={formRef} initialValues={initialValues} autoComplete='off'>
            <Table
              className='contracts-list'
              columns={tableColumns}
              dataSource={processTableData(bargeGridData[0], bargeGridData[1])}
              pagination={false}
              bordered={false}
              size='small'
              scroll={{ x: 800 }}
            />
            <br></br>
            <Flex justify='flex-end' align='center'>
              <Space size={20}>
                <Typography className='small-text'>Total Penalty / Bonus :</Typography>
                <Typography><b>{internationalFormattedValue(totalBonusPrice, 3)}</b></Typography>
              </Space>
            </Flex>

            <Flex justify='flex-end' align='center'>
              <Space size={20}>
                <Typography className='small-text'>Adjusted Price :</Typography>
                <Typography><b>{internationalFormattedValue(qualityAdjustPrice, 3)}</b></Typography>
              </Space>
            </Flex>
          </Form>
        </Card>
      }
    </div>
  )
}

export default QualityPriceAdjustmentsDeatils;