import {
  CalculationFilterDataReq,
  CalculationTableDto,
  CommercialCoalCostingService,
  CommercialGetAllDto,
  ContractModesEnum,
  ContractStatusEnum,
  ContractTermEnum,
  CostingTypesEnum,
  PerFormaReqIdDto,
  PerformaAdvanceDataDto,
  ProcurementContractTypeEnum,
  ReferenceFeatures,
} from '@exportx/shared-models-and-services';
import {
  Button,
  Card,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Affix
} from 'antd';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import {
  AlertMessages,
  isPermissionExist,
  useAuthState,
} from '../../../../common';
import { QualityPriceAdjustments } from '../common';

import { useLocation, useNavigate } from 'react-router-dom';
import CommercialCostingsBargeSelection from '../common/cm-coal-costings-bg-selection';
import {
  MarketingAdvanceAdjustment,
} from './marketing-advance-adjustment';
import {
  MarketingFilterComponent,
} from './marketing-filter';
import MarketingPriceAdjustment from './marketing-price-adjustment';
import moment from 'moment';

interface IPropsCommercialCoalCostings {
  contractType: ContractModesEnum;
  referenceFeatures: ReferenceFeatures;
  pcrContractType?: ProcurementContractTypeEnum;
  costingType?: CostingTypesEnum;
}

export const MarketingCoalCoasting = (props: IPropsCommercialCoalCostings) => {
  const [formRef] = Form.useForm();
  const [initialValues, setInitialValues] = useState<CommercialGetAllDto>();
  const { contractType, pcrContractType, costingType } =
    props;
  const [bargeData, setBargeData] = useState<CalculationTableDto[]>([]);
  const [totalBargeQuantity, setTotalBargeQuantity] = useState<number>();
  const [bargeSelection, setBargeSelection] = useState<any>([]);
  const [bargeSelectionRowKeys, setBargeSelectionRowKeys] = useState<any[]>([]);
  const cmService = new CommercialCoalCostingService();
  const { authContext } = useAuthState();
  const [performaAdvance, setPerformaAdvance] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [adjustedData, setAdjustedData] = useState<any[]>([]);

  let location = useLocation();
  const navigate = useNavigate();
  const cmCostingId: any = queryString.parse(location.search)?.costingId;

  const [dummyRefresh, setDummyRefresh] = useState<number>(0);
  const [invoiceContracts, setInvoiceContracts] = useState<
    PerformaAdvanceDataDto[]
  >([]);
  const [accessId, setAccessId] = useState({});
  const [createPermission, setCreatePermission] = useState(false);
  const [viewPermission, setViewPermission] = useState(false);

  const [taxTotal, setTaxTotal] = useState<any>({
    taxTotal1: 0,
    taxTotal2: 0,
    tdsTotal: 0,
    invoiceTotal: 0,
    exchangeRateValue: 0,
    fcTotal: 0,
    fcCharges: 0,
    freightPricePmt: 0,
    totalCoalPrice: 0,
  });

  useEffect(() => {
    let permission;
    if (
      contractType === ContractModesEnum.MARKETING_FEES &&
      costingType === CostingTypesEnum.MARKETING_FEES
    ) {
      permission = [376];
      setViewPermission(isPermissionExist([376]));
      setCreatePermission(isPermissionExist([375]));
      setAccessId({
        create: 383,
        update: 385,
        delete: 386,
        approve: 387,
        reject: 388,
        release: 389,
        files: 390,
        purchaseEdit: 377,
      });
    }
    if (!isPermissionExist(permission)) navigate('/');
  }, [contractType, costingType]);

  useEffect(() => {
    if (cmCostingId) {
      getAllCommercialData(cmCostingId);
    }
  }, [cmCostingId]);

  const mfCommercialBargeData = (values) => {
    cmService
      .getBargesDataForMFCommercial(values)
      .then((res) => {
        if (res.status) {
          setBargeData(res.data);
          formRef.setFieldValue('allocatedId', res.data[0].allocatedId);
          formRef.setFieldValue('businessNumber', res.data[0].bnId);
        } else {
          setBargeData([]);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setBargeData([]);
      });
  };

  const loadBargesOnClickHandler = (values) => {
    setBargeSelection([]);
    setBargeSelectionRowKeys([]);
    const req = new CalculationFilterDataReq(
      authContext.defaultPlant,
      values.businessNumber,
      values.contractId,
      values.qualityId,
      values.incoterm,
      values.purchaseType,
      contractType,
      values.pcrContractId,
    );
    mfCommercialBargeData(req);
  };

  const qualityOnChange = (e: number) => {
    setDummyRefresh((prev) => prev + 1);
  };


  const getAllCommercialData = (id: string) => {
    const req = new PerFormaReqIdDto(
      id,
      contractType,
      authContext.defaultPlant,
    );
    cmService
      .getAllCommercialCostingsData(req)
      .then((res) => {
        if (res.status) {
          setInitialValues(res.data);
          const req: any = new CalculationFilterDataReq(
            authContext.defaultPlant,
            res.data.businessNumber,
            res.data.contractId,
            res.data.qualityId,
            res.data.incoterm,
            res.data.purchaseType,
            contractType,
            res.data.pcrContractId,
          );
          // sales commercial
          cmService
            .getBargesDataForMFCommercial(req)
            .then((response) => {
              if (res.status) {
                const bargeId = [];
                const bargesData = [];
                for (const bg of res.data.transactionBarges) {
                  bargeId.push(bg.bgUId);
                  const bgD = response.data.filter(
                    (rec) => rec.bgUId === bg.bgUId,
                  )[0];
                  bargesData.push(bgD);
                }
                rowSelection.onChange(bargeId, bargesData);
                setBargeData(response.data);
                setTaxTotal((prev) => {
                  return {
                    ...prev,
                    totalCoalPrice:
                      Number(res.data?.totalBargeQuantity) *
                      (res.data.exchangeRateValue
                        ? Number(res.data.exchangeRateValue)
                        : 1),
                  };
                });
                setTotalBargeQuantity(Number(res.data.totalBargeQuantity));
                formRef.setFieldValue(
                  'totalBargeQuantity',
                  Number(res.data.totalBargeQuantity),
                );
                qualityOnChange(Number(res.data.totalBargeQuantity));
                // setPerformaAdvance(response.perFormaData);
                setAdjustedData(res.data.advanceTarnsactionsData);
                const advTotal = Number(
                  res.data.advanceTarnsactionsData
                    .reduce((a, c) => a + Number(c.totalAdjustedAmount), 0)
                    .toFixed(2),
                ).toLocaleString();
                formRef.setFieldsValue({
                  // ...res?.data,
                  taxId1: res.data?.taxId1,
                  taxId2: res.data?.taxId2,
                  tdsId: res.data?.tdsId,
                  totalBargeQuantity: res.data?.totalBargeQuantity,
                  portType: res.data?.portType,
                  totalAdjustedValue: advTotal,
                  incoterm: res.data?.incoterm,
                });
              } else {
                setBargeData([]);
              }
            })
            .catch((err) => {
              console.log(err.message);
              setBargeData([]);
            });

          // setTotalBargeQuantity(Number(res.data.totalBargeQuantity.toFixed(2)));
        } else {
          AlertMessages.getErrorMessage(res.internalMessage);
          setInitialValues(null);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setInitialValues(null);
      });
  };

  const rowSelection = {
    columnTitle: <></>,
    onChange: (selectedRowKeys: string[], selectedRows: any[]) => {
      setBargeSelection(selectedRows);
      setBargeSelectionRowKeys(selectedRowKeys);
      let sum: number = 0;
      for (const t of selectedRows) {
        sum += Number(t.bargeQuantityInMt);
      }
      formRef.setFieldsValue({
        adjustedPriceValue: selectedRows[0]?.coalBasePrice,
        totalBargeQuantity: sum.toFixed(3),
        freightPricePmt: selectedRows[0]?.freightPricePmt,

      })
      setDummyRefresh((prev) => prev + 1);
    },
    getCheckboxProps: (record) => {
      if (cmCostingId) {
        record.isBargeMapped = false;
      }
      if (record.isBargeMapped) {
        if (record.isBargeMapped) {
          return { disabled: true };
        }
      }

      return {};
    },
    // with qualityGroupId validation
    // getCheckboxProps: (record) => {
    //     if (paramsId || cmCostingId) {
    //         record.isBargeMapped = false
    //     };
    //     if (bargeSelection[0]?.qualityGroupId || record.isBargeMapped) {
    //         if (bargeSelection[0]?.qualityGroupId !== record?.qualityGroupId || record.isBargeMapped) {
    //             return { disabled: true };
    //         };
    //     };

    //     return {};
    // },
    selectedRowKeys: bargeSelectionRowKeys,
  };

  const submitHandler = () => {
    formRef.validateFields().then((values) => {
      const gcvAdjustment = formRef.getFieldValue('gcvAdjustment');
      const ashPriceAdjustment = formRef.getFieldValue('ashPriceAdjustment');
      const tsPriceAdjustment = formRef.getFieldValue('tsPriceAdjustment');
      const tsRejection = formRef.getFieldValue('tsRejection');
      const ashRejection = formRef.getFieldValue('ashRejection');
      const gcvRejection = formRef.getFieldValue('gcvRejection');
      const barges = [];
      for (const bg of bargeSelection) {
        barges.push({
          bargeId: bg.bgUId,
          coalPricePerMt: bg.coalBasePrice,
          baseCurrency: values.baseCurrency,
          quoteCurrency: authContext.defaultPlantCurrency,
          exchangeRate: values.exchangeRateValue,
          exchangeDate: values.exchangeDate ? moment(values.exchangeDate).format('YYYY-MM-DD') : null,
          total: taxTotal.totalPayable,
          qualityGroupId: bg.qualityGroupId,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          cmBgTransactionId: initialValues ? bg.cmBgTransactionId : undefined,
          purchasingTerms: bg.purchasingTerms ? bg.purchasingTerms : '',
        });
      }
      cmService
        .saveCommercialCostings({
          ...values,
          gcvAdjustment,
          ashPriceAdjustment,
          tsPriceAdjustment,
          tsRejection,
          ashRejection,
          gcvRejection,
          ...taxTotal,
          costingDate: values?.costingDate ? moment(values?.costingDate).format('YYY-MM-DD') : null,

          costingInvoiceTotal: taxTotal?.amountAfterTax,
          amountBeforeTax: taxTotal?.amountBeforeTax,
          totalPayable: taxTotal?.totalPayable,
          amountAftTax: taxTotal?.amountAfterTax,
          bpId: bargeSelection[0]?.coalSupplierId,
          costingType: costingType,
          transactionBarges: barges,
          plantCode: authContext.defaultPlant,
          userName: authContext.user.userName,
          quoteCurrency: authContext.defaultPlantCurrency,
          coalPricePMT: bargeSelection[0]?.coalBasePrice,
          contractType: contractType,
          incoterm: bargeSelection[0]?.incoterm,
          costingNo: initialValues?.costingNo,
          adjustmentTransactions: adjustedData,
          vendorId: bargeSelection[0]?.vendorId,
        })
        .then((res) => {
          if (res.status) {
            AlertMessages.getSuccessMessage(res.internalMessage);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          } else {
            AlertMessages.getErrorMessage(res.internalMessage);
          }
        });
    });
  };

  return (
    <>
      <h5>{`${costingType} Costings`}</h5>
      <Row>
        <Col span={24}>
          {createPermission && (
            <MarketingFilterComponent
              key={cmCostingId}
              formRef={formRef}
              loadBargesOnClickHandler={loadBargesOnClickHandler}
              initialValues={initialValues}
              contractType={contractType}
              setBargeData={setBargeData}
              pcrContractType={pcrContractType}
            />
          )}
        </Col>
      </Row>

      <br></br>

      <Row gutter={20}>
        <Col span={14}>
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Barge Details</h6>}
          >
            <CommercialCostingsBargeSelection
              key={cmCostingId}
              bargeGridData={bargeData}
              rowSelection={rowSelection}
            />
            <br />
            <Flex align='center' justify='flex-end'>
              <Form form={formRef} layout="horizontal">
                <Form.Item name={'totalBargeQuantity'} label={<b>Quantity</b>}>
                  <Input
                    type="number"
                    placeholder="Quantity"
                    onChange={(e) =>
                      qualityOnChange(Number(e.target.value))
                    }
                  ></Input>
                </Form.Item>
              </Form>
            </Flex>
          </Card>
          {(bargeSelection[0]?.purchasingTerms === ContractTermEnum.SPEC || bargeSelection[0]?.noPriceAdjustment === 'false') &&

            <Card
              className="card-shadow"
              styles={{ body: { padding: '0px 15px 15px 10px' } }}
              style={{ marginBottom: 8 }}
              title={<h6>Quality Adjustment</h6>}
            >
              <QualityPriceAdjustments
                contractType={contractType}
                formInitialValues={initialValues}
                selectedRowData={bargeSelection[0]}
                setDummyRefresh={setDummyRefresh}
                commercialProp={"Commercial"}
                formRef={formRef}
                cmCostingId={cmCostingId}

              />
            </Card>
          }
          <Card
            className="card-shadow"
            styles={{ body: { padding: '0px 15px' } }}
            style={{ marginBottom: 8 }}
            title={<h6>Advance Adjustment</h6>}
          >
            <MarketingAdvanceAdjustment
              key={cmCostingId}
              bargeSelectionRowKeys={bargeSelectionRowKeys}
              formRef={formRef}
              vendorId={bargeSelection[0]?.coalSupplierId}
              contractId={bargeSelection[0]?.contractId}
              totalInvoice={Number(taxTotal?.invoiceTotal)}
              performaAdvance={performaAdvance}
              setAdjustedData={setAdjustedData}
              adjustedData={adjustedData}
              setInvoiceContracts={setInvoiceContracts}
              invoiceContracts={invoiceContracts}
              costingType={costingType}
              setDummyRefresh={setDummyRefresh}
            />
          </Card>
        </Col>
        <Col span={10}>


          <Card
            // className="default-card-class"
            title={'Price Adjustment'}
          >
            <MarketingPriceAdjustment
              key={cmCostingId}
              initialValues={initialValues}
              taxTotal={taxTotal}
              setTaxTotal={setTaxTotal}
              formRef={formRef}
              bargeSelection={bargeSelection[0]}
              dummyRefresh={dummyRefresh}
              setDummyRefresh={setDummyRefresh}
            />
          </Card>
        </Col>
      </Row>

      <Affix offsetBottom={20}>
        <Button className='new-button' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()} style={{ float: 'right' }}>Submit Invoice</Button>
      </Affix>

    </>
  );
};

export default MarketingCoalCoasting;
