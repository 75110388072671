import { internationalFormattedValue } from "@exportx/ui-utils";
import { Document, Page, StyleSheet, Text, View, Font } from "@react-pdf/renderer";

interface LTCReportProps {
  costingData: any;
}

export const LTCReportDocument = (props: LTCReportProps) => {
  const { costingData } = props;

  Font.register({
    family: "Roboto",
    fonts: [
      { src: "https://fonts.gstatic.com/s/roboto/v29/KFOmCnqEu92Fr1Me5Q.ttf" },
      { src: "https://fonts.gstatic.com/s/roboto/v29/KFOlCnqEu92Fr1MmWUlvAw.ttf", fontWeight: "bold" },
    ],
  });
  
  const styles = StyleSheet.create({
    page: {
      width: "100%",
      height: "80vh",
      fontFamily: "Helvetica",
      fontSize: 8,
      paddingLeft: 20,
      paddingRight: 20,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    view: {
      width: "100%",
      height: "100vh",
      fontFamily: "Helvetica",
      fontSize: 8,
      flexDirection: "column",
    },
    title: {
      textAlign: "center",
      fontSize: 14,
      fontFamily: "Roboto",
      fontWeight: "bold", 
      paddingBottom: 8,
    },
    client: {
      width: "100%",
      fontFamily: "Roboto",
      flexDirection: "row", 
      alignItems: "center", 
      justifyContent: 'space-between',
      paddingHorizontal: 25,
      fontSize: 10,
      fontWeight: "ultrabold"
    },
    section: {
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      borderBottom: 0.65,
      borderBottomColor: "#D3D3D3",
    },
    column: {
      width: 200,
      height: 30,
      flexDirection: "row",
      justifyContent: "flex-end",
      alignItems: "center",
      paddingLeft: 15,
      backgroundColor: "#f2f7fd",
      fontSize: 10,
      fontWeight: "bold",
      color: "grey",
    },
    heading: {
      width: 180,
      height: 30,
      flexDirection: "row",
      alignItems: "center",
      paddingLeft: 10,
      backgroundColor: "#f2f7fd",
      fontSize: 10,
      fontWeight: "bold",
      color: "grey",
    },
    data: {
      width: 120,
      fontSize: 9,
      fontWeight: "bold",
      textAlign: "center"
    },
    comments: {
      marginTop: 4,
      flexDirection: "row",
      paddingHorizontal: 25,
      marginVertical: 20,
      fontSize: 10,
      fontWeight: "bold",
      color: "grey",
    },
    table: { 
      width: "100%", 
      height: "auto", 
      borderWidth: 0.65, 
      borderColor: "#D3D3D3",
      borderRadius: 8,
      marginTop: 20
    },
})
  return (<>
    <Document>
      <Page size={"A4"} style={styles.view}>
        <View
          style={{
            borderBottom: 0.65,
            borderBottomColor: "#D3D3D3",
            // marginBottom: 30,
            paddingBottom: 10,
            paddingTop: 25,
            backgroundColor: "#f2f7fd"
          }}
        >
          <Text style={styles.title}>VESSEL CLOSING - {costingData?.mvName}</Text>
        </View>
        <View style={styles.page}>
          <View style={styles.table}>
            <View style={styles.section}>
              <View style={[styles.heading,{borderTopLeftRadius: 8}]}><Text></Text></View>
              <View style={styles.column}><Text>OWNER</Text></View>
              <View style={styles.column}><Text>SUPPLIER</Text></View>
              <View style={[styles.column,{ paddingRight: 20, borderTopRightRadius: 8}]}><Text>BUYER</Text></View>
            </View> 
            <View style={styles.section}>
              <View style={styles.heading}><Text>Business Partner</Text></View>
              <Text style={styles.data}>{costingData?.owner.name || '--'}</Text>
              <Text style={styles.data}>{costingData?.supplier.name || '--'}</Text>
              <Text style={styles.data}>{costingData?.buyer.name || '--'}</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>Quantity</Text></View>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.owner.quantity, 3)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.supplier.quantity, 3)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.buyer.quantity, 3)}</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>Demurrage</Text></View>
              <Text style={styles.data}><Text>{internationalFormattedValue(costingData?.owner.demurrageRate, 3)}</Text></Text>
              <Text style={styles.data}><Text>{internationalFormattedValue(costingData?.supplier.demurrageRate, 3)}</Text></Text>
              <Text style={styles.data}><Text>{internationalFormattedValue(costingData?.buyer.demurrageRate, 3)}</Text></Text>
            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>Despatch</Text></View>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.owner.despatchRate)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.supplier.despatchRate)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.buyer.despatchRate)}</Text>

            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>LTC Load Port Status</Text></View>
              <Text style={styles.data}>{costingData?.owner.dischargePortStatus || '--'}</Text>
              <Text style={styles.data}>{costingData?.supplier.dischargePortStatus || '--'}</Text>
              <Text style={styles.data}>{costingData?.buyer.dischargePortStatus || '--'}</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>LTC LOAD PORT AMOUNT</Text></View>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.owner.loadPortAmount)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.supplier.loadPortAmount)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.buyer.loadPortAmount)}</Text>
            </View>
            <View style={styles.section}>
              <View style={styles.heading}><Text>LTC DISCHARGE PORT STATUS</Text></View>
              <Text style={styles.data}>{costingData?.owner.loadPortStatus || '--'}</Text>
              <Text style={styles.data}>{costingData?.supplier.loadPortStatus || '--'}</Text>
              <Text style={styles.data}>{costingData?.supplier.loadPortStatus || '--'}</Text>
            </View>
            <View style={[styles.section, {borderWidth: 0}]}>
              <View style={[styles.heading, {borderBottomLeftRadius: 8}]}><Text>LTC DISCHARGE PORT AMOUNT</Text></View>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.owner.dischargePortAmount, 3)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.supplier.dischargePortAmount, 3)}</Text>
              <Text style={styles.data}>{internationalFormattedValue(costingData?.buyer.dischargePortAmount, 3)}</Text>

            </View>
          </View>

          <View style={styles.comments}>
            <Text style={{ marginTop: 5 }}>COMMENTS : </Text> 
            <View style={{ flexDirection: 'column',  }}>
            {costingData?.comments?.comments?.map((item, index) => {
              return <Text style={{ color: "black", marginTop: 5 }} key={index}>{index+1}. {item.comment}</Text>
            })}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  </>)
}

export default LTCReportDocument;