import { CommercialGetAllDto, CompanyCodeReq, CompanyTdsService, ContractModesEnum, ContractStatusEnum, ContractTermEnum, CurrencyConverterService, CurrencyDto, CurrencyExchangeRequestDto, CurrencyService, PriceTypeEnum, TaxDropDownDto, TaxesService, TdsResModel } from '@exportx/shared-models-and-services';
import { getLocalFormat, getNumberFromLocalString, internationalFormattedValue } from '@exportx/ui-utils';
import { Button, Col, Divider, Drawer, Flex, Form, FormInstance, Input, InputNumber, Popover, Row, Select, Typography } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { AlertMessages, useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';
import { InfoCircleOutlined } from '@ant-design/icons';

interface PriceIProps {
  formRef: FormInstance<any>;
  bargeSelection: any;
  taxTotal: any;
  setTaxTotal: React.Dispatch<any>;
  initialValues?: CommercialGetAllDto;
  setDummyRefresh: React.Dispatch<React.SetStateAction<number>>;
  dummyRefresh: number;

}

const ProcurementPriceAdjustment = (props: PriceIProps) => {
  const { formRef, bargeSelection, setTaxTotal, taxTotal, initialValues, setDummyRefresh, dummyRefresh } = props;
  const { Option } = Select;
  const { Text } = Typography;
  const [tdsModel, setTdsModel] = useState<TdsResModel[]>([]);
  const [taxDropDown, setTaxDropDown] = useState<TaxDropDownDto[]>([])
  const [currencies, setCurrencies] = useState<CurrencyDto[]>([]);

  const { authContext } = useAuthState();
  const companyTdsService = new CompanyTdsService();
  const taxesService = new TaxesService();
  const currencyConverterService = new CurrencyConverterService();
  const currencyService = new CurrencyService();
  const currency = authContext.defaultPlantCurrency;
  const [exchangeDateVisible, setExchangeDateVisible] = useState<boolean>(true);

  useEffect(() => {
    getTdsData();
    taxActiveDropDown();
    getCurrency();
  }, []);

  useEffect(() => {
    formRef.setFieldValue("baseCurrency", bargeSelection?.baseCurrency)
    formRef.setFieldValue("quoteCurrency", authContext.defaultPlantCurrency)
    visibleDisable(bargeSelection?.baseCurrency, currency);
    // if (bargeSelection?.purchasingTerms === ContractTermEnum.NON_SPEC) {
    formRef.setFieldValue('adjustedPriceValue', bargeSelection?.coalBasePrice);
    setDummyRefresh(prev => prev + 1)
    // }
  }, [bargeSelection]);


  useEffect(() => {
    if (initialValues) {
      formRef.setFieldValue('baseCurrency', initialValues?.baseCurrency);
      formRef.setFieldValue('adjustedPriceValue', Number(initialValues.adjustedPriceValue));
      formRef.setFieldValue('exchangeDate', initialValues.exchangeDate);
      formRef.setFieldValue('adjustedPriceValue', Number(initialValues.adjustedPriceValue));
      let transactionData = initialValues.transactionBarges
      formRef.setFieldValue('currencyType', transactionData[0]?.currencyType)
      formRef.setFieldValue('exchangeRateAdjustment', transactionData[0]?.exchangeRateAdjustment)

      if (initialValues?.baseCurrency !== authContext.defaultPlantCurrency) {
        exChangeDateHandler()
      } else {
        visibleDisable(initialValues?.baseCurrency, currency);
      };
      pricePmtOnChange(Number(initialValues.adjustedPriceValue));
    }

  }, [initialValues]);

  useEffect(() => {
    calculationHandler();
  }, [dummyRefresh]);

  const calculationHandler = () => {
    const finalQty = formRef.getFieldValue('totalBargeQuantity') ? Number(formRef.getFieldValue('totalBargeQuantity')) : 0;
    const finalPrice = formRef.getFieldValue('adjustedPriceValue') ? Number(formRef.getFieldValue('adjustedPriceValue')) : 0;
    let exchangeRate = formRef.getFieldValue('exchangeRate') ? Number(formRef.getFieldValue('exchangeRate')) : 1;
    let displayExchangeRate = formRef.getFieldValue('displayExchangeRate') ? formRef.getFieldValue('displayExchangeRate') : 1;
    let exchAdjustValue = formRef.getFieldValue('exchangeRateAdjustment') ? Number(formRef.getFieldValue('exchangeRateAdjustment')) : 0;

    if (exchangeDateVisible) {
      exchangeRate = 1;
      exchAdjustValue = 0;
      displayExchangeRate = 1;

    }
    const exchgRateAftAdjust = exchangeRate + exchAdjustValue;
    const displayExchangeRateAfterAdjust = displayExchangeRate + exchAdjustValue
    // Coal Calculation

    const priceAfterExcg = finalPrice * exchgRateAftAdjust;
    const totalCoalPrice = finalQty * priceAfterExcg;

    const amountBeforeTax = totalCoalPrice;
    const tax1 = formRef.getFieldValue('taxId1') ? taxDropDown?.filter((rec) => rec?.taxId === formRef.getFieldValue('taxId1'))[0]?.taxPercentage : 0;
    const tax2 = formRef.getFieldValue('taxId2') ? taxDropDown?.filter((rec) => rec?.taxId === formRef.getFieldValue('taxId2'))[0]?.taxPercentage : 0;
    const tds = formRef.getFieldValue('tdsId') ? tdsModel?.filter((rec) => rec?.companyTdsId === formRef.getFieldValue('tdsId'))[0]?.tdsValue : 0;
    const taxTotal1 = (tax1 / 100) * amountBeforeTax;
    const taxTotal2 = (tax2 / 100) * amountBeforeTax;
    const tdsTotal = (tds / 100) * amountBeforeTax;
    const amountAfterTax = amountBeforeTax + taxTotal1 + taxTotal2 - tdsTotal;
    const advanceAdjustments = formRef.getFieldValue('totalAdjustedValue') ? getNumberFromLocalString(formRef.getFieldValue('totalAdjustedValue')) : 0;

    const totalPayable = amountAfterTax - advanceAdjustments;

    formRef.setFieldsValue({
      // Coal
      priceAfterExcg: priceAfterExcg,
      totalCoalPrice: totalCoalPrice,
      amountBeforeTax: amountBeforeTax,
      taxTotal1: taxTotal1,
      taxTotal2: taxTotal2,
      tdsTotal: tdsTotal,
      amountAfterTax: amountAfterTax,
      totalPayable: totalPayable,
    });

    setTaxTotal((prev) => {
      return {
        ...prev,
        totalBargeQuantity: finalQty,
        adjustedPriceValue: finalPrice,
        exchangeRate: exchangeRate,
        exchangeRateAdjustment: exchAdjustValue,
        displayExchangeRate: displayExchangeRate,
        displayExchangeRateAfterAdjust: displayExchangeRateAfterAdjust,
        // Coal
        priceAfterExcg: priceAfterExcg,
        totalCoalPrice: totalCoalPrice,
        amountBeforeTax: amountBeforeTax,
        taxTotal1: taxTotal1,
        taxTotal2: taxTotal2,
        tdsTotal: tdsTotal,
        amountAfterTax: amountAfterTax,
        totalPayable: totalPayable,


      }
    });
  };



  const visibleDisable = (bargeSelectionCurrency: string, systemCurrency: string) => {
    if (bargeSelectionCurrency === systemCurrency) {
      setExchangeDateVisible(true);
      formRef.setFieldValue('exchangeDate', undefined);
    } else {
      setExchangeDateVisible(false);
    };
    setDummyRefresh(prev => prev + 1)
  };

  if (initialValues?.exchangeDate) {
    const ex: any = moment(initialValues.exchangeDate)
    initialValues.exchangeDate = ex;
  }

  const getTdsData = () => {
    const req = new CompanyCodeReq();
    req.company = authContext.defaultPlant;
    req.date = moment().format('YYYY-MM-DD');
    companyTdsService.getTdsByCompanyCode(req).then(res => {
      if (res.status) {
        setTdsModel(res.data);
      } else {
        setTdsModel([]);
      }
    }).catch(err => {
      console.log(err);
      setTdsModel([]);
    });
  };

  const taxActiveDropDown = () => {
    taxesService.getActiveTaxDropDown().then(res => {
      if (res.status) {
        setTaxDropDown(res.data)
      } else {
        setTaxDropDown([])
      }
    }).catch(err => {
      console.log(err.message);
      setTaxDropDown([]);
    })
  };

  const getCurrency = () => {
    currencyService.getAllCurrenciesForDropDown().then(res => {
      if (res.status) {
        setCurrencies(res.data);
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  };

  const getCurrencyExchangeRate = (fromCurrency, toCurrency, date, currencyType, adjustment) => {
    const req = new CurrencyExchangeRequestDto(fromCurrency, toCurrency, moment(date).format('YYYY-MM-DD'), currencyType, adjustment);
    if (moment(req.exchangeDate).format('YYYY-MM-DD') === 'Invalid date') {
      return;
    }
    currencyConverterService.getCurrencyExchangeRate(req).then(res => {
      if (res.status) {
        if (res.data.exchangeRate) {
          formRef.setFieldValue('exchangeRate', res.data.exchangeRate)
          formRef.setFieldValue('displayExchangeRate', res.data.displayExchangeRate)
          const exchangeRateValue = Number(res.data.exchangeRate) * (initialValues?.adjustedPriceValue ? Number(initialValues?.adjustedPriceValue) : Number(formRef.getFieldValue('qualityAdjustPrice')));
          setTaxTotal((prev) => {
            return {
              ...prev,
              exchangeRateValue: exchangeRateValue,
              exchangeRate: Number(res.data.exchangeRate),
              displayExchangeRate: Number(res.data.displayExchangeRate),
              currencyType: res.data.currencyType,
              purchaseRate: Number(res.data.purchaseRate),
              sellingRate: Number(res.data.sellingRate)
            }
          });

          setDummyRefresh((prev) => prev + 1);
        };
      } else {
        formRef.setFieldValue('exchangeRate', 0)
        formRef.setFieldValue('displayExchangeRate', 0)
        setDummyRefresh((prev) => prev + 1);
      }
    }).catch(err => { console.log(err.message) });
  };



  const exChangeDateHandler = () => {
    const baseCurrency = formRef.getFieldValue('baseCurrency');
    const currencyType = formRef.getFieldValue('currencyType');
    const exchangeDate = formRef.getFieldValue('exchangeDate');
    const exchangeRateAdjustment = formRef.getFieldValue('exchangeRateAdjustment');

    if (currencyType && exchangeDate) {
      getCurrencyExchangeRate(baseCurrency, authContext.defaultPlantCurrency, moment(exchangeDate), currencyType, exchangeRateAdjustment);
    }
  };


  const currencyOnChangeHandler = (value) => {
    visibleDisable(value, currency);
    exChangeDateHandler()
  };


  const suffixSelector = (
    <Form.Item name="baseCurrency" noStyle label="Please Select">
      <Select onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
      >
        {currencies?.map((rec) => {
          return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
        })}
      </Select>
    </Form.Item>
  );

  const suffixSelector2 = (
    <Form.Item name="quoteCurrency" noStyle label="Please Select">
      <Select disabled onChange={currencyOnChangeHandler} filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} allowClear showSearch style={{ width: 60 }}
      >
        {currencies?.map((rec) => {
          return <Option value={rec.currencyCode}>{rec.currencyCode}</Option>
        })}
      </Select>
    </Form.Item>
  );

  const onTaxChange1 = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  };

  const onTaxChange2 = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  }

  const tdsOnchange = (v: string) => {
    setDummyRefresh((prev) => prev + 1);
  };

  const pricePmtOnChange = (value: number) => {
    setDummyRefresh((prev) => prev + 1);
  };


  return (
    <>
      <Form
        form={formRef}
        layout='vertical'
        initialValues={initialValues}
      >
        <Row >
          <Form.Item name={'cmCostingId'} hidden>
            <Input></Input>
          </Form.Item>
        </Row>
        <Row gutter={16} >

          <Col span={8}>
            <Form.Item label={"Adjusted Price PMT"} name={'adjustedPriceValue'}>
              <InputNumber
                onChange={(e) => pricePmtOnChange((Number(e)))}
                addonAfter={suffixSelector}
                disabled={true}>
              </InputNumber>
            </Form.Item>
          </Col>


          <Col span={8}>
            <Form.Item
              label="Currency Type"
              name="currencyType"
            >
              <Select
                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                allowClear
                showSearch
                defaultValue={!formRef.getFieldValue('currencyType') && formRef.setFieldValue('currencyType', "JISDOR")}
                placeholder='Select Currency'
                onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}

              >
                {['JISDOR', 'Middle Rate', 'Tax Rate'].map((item, index) => {
                  return <Option value={item} key={index}>{item}</Option>
                })}
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name={'exchangeDate'} label={"Exchange Date"} >
              <DatePicker onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}
              />
            </Form.Item>
          </Col>
          <Col span={8} >
            <Form.Item label={"Exchange Rate Adjustment"} name={'exchangeRateAdjustment'}>
              <InputNumber
                onChange={exChangeDateHandler}
                disabled={exchangeDateVisible}
              >
              </InputNumber>
            </Form.Item>
          </Col>

        </Row>
        <Row>
          <Col span={24}>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.displayExchangeRate, 3)}  &nbsp;
                {formRef.getFieldValue('currencyType') === 'Middle Rate' && (<Popover
                  content={
                    <>
                      <Typography style={{ fontSize: 10 }}>Buying Rate: <b>{taxTotal?.purchaseRate}</b></Typography>
                      <Typography style={{ fontSize: 10 }}>Selling Rate <b>{taxTotal?.sellingRate}</b></Typography>
                    </>
                  }
                  title="Rates"
                  placement="bottom"
                >
                  <InfoCircleOutlined style={{ color: "#0295D4" }} />

                </Popover>)}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Price After Exchange Rate</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.priceAfterExcg, 3)} {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">Total Coal Price</Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.totalCoalPrice, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading">Total Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(taxTotal?.amountBeforeTax, 3)}  {currency}
              </Text>
            </Flex>
            <Divider dashed={true} />

            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TAX1</Text>
              <Text>
                <Form.Item name={"taxId1"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => onTaxChange1(v)}
                    placeholder='Select tax'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {taxDropDown?.map((rec) => {
                      return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                    })}
                  </Select>
                </Form.Item >

              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.taxTotal1, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TAX2</Text>
              <Text>
                <Form.Item name={"taxId2"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => onTaxChange2(v)}
                    placeholder='Select tax'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {taxDropDown?.map((rec) => {
                      return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                    })}
                  </Select>
                </Form.Item >
              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.taxTotal2, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text">TDS</Text>
              <Text>
                <Form.Item name={"tdsId"}>
                  <Select
                    style={{ width: '160px' }}
                    onChange={(v) => tdsOnchange(v)}
                    placeholder='Select TDS'
                    showSearch
                    allowClear
                    optionFilterProp="children"
                    filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                  >
                    <Option value={undefined}>Not Applicable</Option>
                    {tdsModel?.map((rec) => {
                      return <Option value={rec.companyTdsId} key={rec.companyTdsId}>{rec.tdsName}</Option>
                    })}
                  </Select>
                </Form.Item >

              </Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.tdsTotal, 3)}  {currency}
              </Text>
            </Flex>
            <Divider dashed={true} />
            <Flex justify="space-between" align='center'>
              <Text className="price-card-heading"> Amount</Text>
              <Text className="price-card-heading">
                {internationalFormattedValue(taxTotal?.amountAfterTax, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-text"><b>Advance Adjustment</b></Text>
              <Text className="price-card-text">
                {internationalFormattedValue(taxTotal?.toalFcCharges, 3)}  {currency}
              </Text>
            </Flex>
            <Flex justify="space-between" align='center'>
              <Text className="price-card-green">To be paid to Supplier</Text>
              <Text className="price-card-green">
                {internationalFormattedValue(taxTotal?.totalPayable, 3)}  {currency}
              </Text>
            </Flex>

            {/* <table className="table table-bordered table-sm">
              <tr>
                <th colSpan={2}>Price after Exchange</th>
                <td>
                  <Form.Item name="priceAfterExcg">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("priceAfterExcg"), costingCurrency)}
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th colSpan={2}>Total Coal Price</th>
                <td>
                  <Form.Item name="totalCoalPrice">
                    {({ getFieldValue }) => <span>{getLocalFormat(getFieldValue("totalCoalPrice"), costingCurrency)}</span>}
                  </Form.Item>
                  {/* <Text>{getLocalFormat(taxTotal?.totalCoalPrice, costingCurrency)}</Text> *

                </td>
              </tr>
              <tr>
                <th colSpan={2}>FC Charges</th>
                <td>
                  <Form.Item name="toalFcCharges">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("toalFcCharges"), costingCurrency)}
                  </Form.Item>
                  {/* <Text>{getLocalFormat(taxTotal?.fcCharges, costingCurrency)}</Text> *

          </td>
        </tr>
        {/* <tr>
                                <th colSpan={2}>Freight</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.freightPricePmt, costingCurrency)}</Text>

                                </td>
                            </tr> *
              <tr>
                <th colSpan={2}>Total Amount</th>
                <td>
                  <Form.Item name="amountBeforeTax">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("amountBeforeTax"), costingCurrency)}
                  </Form.Item>

                  {/* <Text>{getLocalFormat(taxTotal?.fcTotal, costingCurrency)}</Text> *

                </td>
              </tr>
              <tr>
                <th>TAX 1</th>
                <td>
                  <Form.Item name={"taxId1"}>
                    <Select
                      style={{ width: '160px' }}
                      onChange={(v) => onTaxChange1(v, taxTotal?.fcTotal)}
                      placeholder='Select tax'
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                    >
                      <Option value={undefined}>Not Applicable</Option>
                      {taxDropDown?.map((rec) => {
                        return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                      })}
                    </Select>
                  </Form.Item >
                </td>
                <td>
                  <Form.Item name="taxTotal1">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("taxTotal1"), costingCurrency)}
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th>TAX 2</th>
                <td>
                  <Form.Item name={"taxId2"}>
                    <Select
                      style={{ width: '160px' }}
                      onChange={(v) => onTaxChange2(v, taxTotal?.fcTotal)}
                      placeholder='Select tax'
                      showSearch
                      allowClear
                      optionFilterProp="children"
                      filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                    >
                      <Option value={undefined}>Not Applicable</Option>
                      {taxDropDown?.map((rec) => {
                        return <Option value={rec.taxId} key={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                      })}
                    </Select>
                  </Form.Item >
                </td>
                <td>
                  <Form.Item name="taxTotal2">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("taxTotal2"), costingCurrency)}
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th><Form.Item label={'TDS'}></Form.Item></th>
                <td>

                </td>
                <td>
                  <Form.Item name="tdsTotal">
                    {({ getFieldValue }) => getLocalFormat(getFieldValue("tdsTotal"), costingCurrency)}
                  </Form.Item>
                </td>
              </tr>
              <tr>
                <th colSpan={2} >
                  Amount
                </th>
                <td>
                  <Form.Item name={'amountAfterTax'}>
                    <InputNumber addonAfter={suffixSelector2} disabled>
                    </InputNumber>
                  </Form.Item>

                </td>

              </tr>
              <tr>
                <th colSpan={2} >
                  Adjusted Against Advances
                </th>
                <td>
                  <Form.Item name={'totalAdjustedValue'}>
                    <InputNumber addonAfter={suffixSelector2} disabled>
                    </InputNumber>
                  </Form.Item>

                </td>

              </tr>
              <tr>
                <th colSpan={2} >
                  To be paid to Supplier
                </th>
                <td>
                  <Form.Item name={'totalPayable'}>
                    <InputNumber addonAfter={suffixSelector2} disabled>
                    </InputNumber>
                  </Form.Item>

                </td>

              </tr>



            </table> */}
          </Col>


        </Row >
        {/* <Button type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()} style={{ float: 'right' }}>Submit Invoice</Button> */}
      </Form >
      {/* <Form
                form={formRef}
                layout='vertical'
                initialValues={initialValues}
            >
                <Row >
                    <Form.Item name={'cmCostingId'} hidden>
                        <Input></Input>
                    </Form.Item>
                </Row>

                <br />
                <Row>
                    <Col span={24}>
                        <Row >
                            <Col span={6} offset={0} >
                                <Form.Item 
                                    label={"Currency Type"}
                                    name={"currencyType"} style={{ width: '80%' }}
                                >
                                    <Select 
                                        filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())} 
                                        allowClear 
                                        showSearch 
                                        defaultValue={!formRef.getFieldValue('currencyType') && formRef.setFieldValue('currencyType', "JISDOR")}
                                        placeholder='Select Currency'
                                        onChange={exChangeDateHandler}
                                        disabled={exchangeDateVisible}

                                    >
                                        {['JISDOR', 'Middle Rate', 'Tax Rate'].map((item, index) => {
                                            return <Option value={item} key={index}>{item}</Option>
                                        })}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={6} >
                                <Form.Item name={'exchangeDate'} label={"Exchange Date"} >
                                    <DatePicker style={{ width: 160 }} onChange={exChangeDateHandler} 
                                    disabled={exchangeDateVisible} 
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={8} offset={2}>
                                <Form.Item label={"Exchange Rate Adjustment"} name={'exchangeRateAdjustment'}>
                                    <InputNumber
                                        onChange={exChangeDateHandler}
                                        disabled={exchangeDateVisible}
                                        >
                                    </InputNumber>
                                </Form.Item>
                            </Col>

                            <Col span={8}>
                                <Form.Item label={"Adjusted Price PMT"} name={'adjustedPriceValue'}>
                                    <InputNumber
                                        onChange={(e) => pricePmtOnChange((Number(e)))}
                                        addonAfter={suffixSelector}
                                        disabled={true}>
                                    </InputNumber>
                                </Form.Item>
                            </Col>

                            <Col>


                            </Col>

                        </Row>


                        <table className="table table-bordered table-sm">
                            <tr>
                                <th colSpan={2}>Exchange Rate</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.displayExchangeRate, currency)}</Text> &nbsp;
                                    {formRef.getFieldValue('currencyType') === 'Middle Rate' && (<Popover
                                        content={
                                            <>
                                               <Typography style={{ fontSize: 10 }}>Purchase Rate: <b>{taxTotal?.purchaseRate}</b></Typography>
                                               <Typography style={{ fontSize: 10 }}>Selling Rate <b>{taxTotal?.sellingRate}</b></Typography>
                                            </>
                                        }
                                        title="Rates"
                                        placement="bottom"
                                    >
                                        <InfoCircleOutlined style={{ color: "#0295D4" }} />

                                    </Popover>)}
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Price after Exchange</th>
                                <td>
                                    <Text>{currency} {taxTotal?.exchangeRateValue.toFixed(3)}</Text>
                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Total Coal Price</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.totalCoalPrice, currency)}</Text>

                                </td>
                            </tr>
                            {/* <tr>
                                <th colSpan={2}>FC Charges</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcCharges, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th colSpan={2}>Freight</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.freightPricePmt, currency)}</Text>

                                </td>
                            </tr> *
                            <tr>
                                <th colSpan={2}>Total Amount</th>
                                <td>
                                    <Text>{getLocalFormat(taxTotal?.fcTotal, currency)}</Text>

                                </td>
                            </tr>
                            <tr>
                                <th>TAX 1</th>
                                <td>
                                    <Form.Item name={"taxId1"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange1(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal1, currency)}</td>
                            </tr>
                            <tr>
                                <th>TAX 2</th>
                                <td>
                                    <Form.Item name={"taxId2"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => onTaxChange2(v, taxTotal?.fcTotal)}
                                            placeholder='Select tax'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {taxDropDown?.map((rec) => {
                                                return <Option value={rec.taxId}>{`${rec.taxName} - ${rec.taxPercentage}`}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.taxTotal2, currency)}</td>
                            </tr>
                            <tr>
                                <th><Form.Item label={'TDS'}></Form.Item></th>
                                <td>
                                    <Form.Item name={"tdsId"}>
                                        <Select
                                            style={{ width: '160px' }}
                                            onChange={(v) => tdsOnchange(v, taxTotal?.fcTotal)}
                                            placeholder='Select TDS'
                                            showSearch
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}
                                        >
                                            <Option value={undefined}>Not Applicable</Option>
                                            {tdsModel?.map((rec) => {
                                                return <Option value={rec.companyTdsId}>{rec.tdsName}</Option>
                                            })}
                                        </Select>
                                    </Form.Item >
                                </td>
                                <td>{getLocalFormat(taxTotal?.tdsTotal, currency)}</td>
                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Amount
                                </th>
                                <td>
                                    <Form.Item name={'costingInvoiceTotal'}>
                                        <InputNumber addonAfter={suffixSelector2} disabled style={{ width: 204 }}>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    Adjusted Against Advances
                                </th>
                                <td>
                                    <Form.Item name={'totalAdjustedValue'}>
                                        <InputNumber addonAfter={suffixSelector2} style={{ width: 204 }} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <tr>
                                <th colSpan={2} >
                                    To be paid to Supplier
                                </th>
                                <td>
                                    <Form.Item name={'remainingBalance'}>
                                        <InputNumber addonAfter={suffixSelector2} style={{ width: 204 }} disabled>
                                        </InputNumber>
                                    </Form.Item>

                                </td>

                            </tr>
                            <th colSpan={2} >
                            </th>
                            <th  >

                                {/* <Button
                                    onClick={submitHandler}
                                    type='primary'>Submit Invoice</Button> *
                                <Button type='primary' disabled={initialValues?.approvalStatus === ContractStatusEnum.ACTIVE ? true : false} onClick={() => submitHandler()}>Submit Invoice</Button>

                            </th>



                        </table>

                    </Col>

                </Row>
            </Form > */}
    </>
  )
}

export default ProcurementPriceAdjustment



