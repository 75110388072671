import React, { useEffect, useState } from "react";
import { Button, Card, Flex, Form, Input, Popover, Space, Table, Typography } from "antd";
import moment from "moment";
import { AlertMessages, StatusTag, useAuthState } from "../../../common";
import { ContractStatusEnum, CostingInvoiceApproveReqDto, CostingInvoiceReqDto, CostingInvoiceService } from "@exportx/shared-models-and-services";
import { getLocalFormat, internationalFormattedValue } from "@exportx/ui-utils";
import ViewFileHandling from "../../../common/file-upload-form/view-files-handling";
import { faCheckCircle, faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ApprovalTracking } from "./approval-tracking";

interface InvoiceDetailsProps {
  costingId: string;
  status: ContractStatusEnum;
  level: ContractStatusEnum;
  invoiceStatus: ContractStatusEnum;
  invApproveDisabled?: boolean;
  includeFreight?: boolean;
  workLog?: any[];
  setDummyRefresh?: React.Dispatch<React.SetStateAction<boolean>>;

}

const { Text } = Typography;

export const InvoiceDetails = (props: InvoiceDetailsProps) => {
  const { costingId, status, level, invApproveDisabled, includeFreight, workLog, setDummyRefresh } = props
  const invoiceService = new CostingInvoiceService();
  const [costingInvoices, setCostingInvoices] = useState([])
  const { authContext } = useAuthState();
  const [activeRejectRow, setActiveRejectRow] = useState(null);
  const [form] = Form.useForm();

  useEffect(() => {
    if (costingId)
      getAllCostingInvoice();
  }, [costingId])

  const getAllCostingInvoice = () => {
    const req = new CostingInvoiceReqDto(costingId, authContext.defaultPlant);
    invoiceService.getAllCostingInvoice(req).then(res => {
      if (res.status) {
        setCostingInvoices(res.data);
      } else {
        setCostingInvoices([]);
        console.log(res.internalMessage);

      }
    }).catch(err => console.log(err.message));

  }

  const approvalHandler = (id) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, status, level)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setDummyRefresh(true);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }
  const rejectedHandler = (id, remarks) => {
    const req = new CostingInvoiceApproveReqDto(id, authContext.user.userName, authContext.defaultPlant, ContractStatusEnum.REJECT, level, costingId, remarks)
    invoiceService.approveOrRejectCostingInvoice(req).then(res => {
      if (res.status) {
        AlertMessages.getSuccessMessage(res.internalMessage);
        setDummyRefresh(true);
        getAllCostingInvoice();
      } else {
        AlertMessages.getErrorMessage(res.internalMessage);
      }
    }).catch(err => console.log(err.message));
  }

  const handleReject = (record) => {
    form.validateFields()
      .then(values => {
        const rejectionMessage = `${authContext.user.userName} rejected Invoice ${record?.invoiceRef} (${record?.invoiceNo}) - ${values.remarks}`;
        rejectedHandler(record?.invoiceId, rejectionMessage);
        setActiveRejectRow(null);
      })
      .catch(err => console.log(err.message));
  };

  const columns: any = [
    {
      title: 'Invoice Ref',
      dataIndex: 'invoiceRef',
      key: 'invoiceNo',
      render: (text, record) => {
        return (
          <>
            <Typography>{text ?? 'N/A'}</Typography>
          </>

        )
      },
    },
    {
      title: 'Invoice No',
      dataIndex: 'invoiceNo',
      key: 'invoiceNo',
      render: (text, record) => {
        return (
          <>
            <Typography>{text}</Typography>
            <Typography className='small-text'>{record.invoiceDate !== undefined ? moment(record.invoiceDate).format('DD-MMM-YYYY') : "N/A"}</Typography>
          </>

        )
      },
    },
    {
      title: 'Invoice Amount',
      dataIndex: 'invoiceAmount',
      key: 'invoiceAmount',
      render: (text) => {
        return (
          <>
            <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
            <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

          </>
        )
      }

    },
    ...includeFreight ? [
      {
        title: 'Coal %',
        dataIndex: 'coal_percent',
        key: 'coal_percent',
        render: (text) => <Typography className='small-text'>{text}</Typography>

      },

      {
        title: 'Coal Amount',
        dataIndex: 'coal_amount',
        key: 'coal_amount',
        render: (text) => {
          return (
            <>
              <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
              <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

            </>
          )
        }
      },
      {
        title: 'Freight %',
        dataIndex: 'freight_percent',
        key: 'freight_percent',
        render: (text) => <Typography className='small-text'>{text}</Typography>
      },
      {
        title: 'Freight Amount',
        dataIndex: 'freight_amount',
        key: 'freight_amount',
        render: (text) => {
          return (
            <>
              <Text className='small-text'>{internationalFormattedValue(text, 2)}</Text>
              <span className='currency-style'>{authContext.defaultPlantCurrency}</span>

            </>
          )
        }
      },
    ] : [
      {
        title: 'Invoice %',
        dataIndex: 'invoicePercent',
        key: 'invoicePercent',
        render: (text) => <Typography className='small-text'>{text}</Typography>

      }
    ],

    {
      title: 'Invoice File',
      dataIndex: 'filesData',
      render: (value, record) => {
        return <>
          <ViewFileHandling filesData={value.length ? value : []} required={true} />

        </>
      }

    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value, record) => {
        return <>
          <StatusTag status={value} />
        </>
      }
    },
    ...level !== ContractStatusEnum.FREEZED ? [
      {
        title: 'Action',
        dataIndex: 'businessNo',
        key: 'businessNo',
        fixed: 'right',
        width: 170,
        render: (value, record) => {
          if ((level === ContractStatusEnum.BUSINESS_APPROVAL && record.status === ContractStatusEnum.PENDING_APPROVAL) || (level === ContractStatusEnum.AUDIT_APPROVAL && record.status === ContractStatusEnum.PENDING_AUDIT_APPROVAL)) {
            return (
              <Flex>
                <Button
                  disabled={invApproveDisabled}
                  size="small"
                  className="approve"
                  onClick={() => { approvalHandler(record?.invoiceId); }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} />
                  Approve
                </Button>&nbsp;&nbsp;
      
                <Popover
                  trigger="click"
                  open={activeRejectRow === record?.invoiceId}
                  onOpenChange={(open) => setActiveRejectRow(open ? record?.invoiceId : null)}
                  overlayInnerStyle={{ width: 300 }}
                  content={
                    <Form
                      form={form}
                      layout="vertical"
                      autoComplete="off"
                    >
                      <Form.Item
                        label="Remarks"
                        name="remarks"
                        rules={[{ required: true, message: "Enter Remarks" }]}
                      >
                        <Input.TextArea placeholder="Remarks" />
                      </Form.Item>
      
                      <Flex align="middle" justify="flex-end">
                        <Space size={10}>
                          <Button
                            size="small"
                            onClick={() => {setActiveRejectRow(null); form.resetFields()} }
                          >
                            Cancel
                          </Button>
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => handleReject(record)}
                          >
                            Reject
                          </Button>
                        </Space>
                      </Flex>
                    </Form>
                  }
                >
                  <Button
                    disabled={invApproveDisabled}
                    size="small"
                    className="reject"
                  >
                    <FontAwesomeIcon icon={faCircleXmark} /> Reject
                  </Button>
                </Popover>
              </Flex>
            )
          }
        }
      }] : []
  ]

  return (
    <Card
      bordered={false}
      className="card-shadow mb-2"
      styles={{ body: { padding: '0px 10px 15px' } }}
      title={<h6>Invoice Details</h6>}
      extra={<ApprovalTracking history={workLog} />}
    >
      <Table dataSource={costingInvoices}
        columns={columns}
        pagination={false}
        className='contracts-list'
        size="small"
        scroll={{ x: 1000 }}

        bordered={false}

      />
    </Card>

  )

}