import { BusinessAllocationDropdownDto, BusinessNumberService, CalculationTableDto, ContractIdRequest, ContractModesEnum, CostingTypesEnum, PcPurchaseTypeReq, ProcurementContractTypeEnum, PurchaseContractService, PurchaseDropDownDto, SalesContractService } from '@exportx/shared-models-and-services';
import { Button, Card, Col, Form, FormInstance, Input, Row, Select } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useAuthState } from '../../../../common';
import DatePicker from '../../../../common/data-picker/date-picker';

const { Option } = Select;

export interface ICoalCostingFilterInitialValues {
    businessNumber: string;
    contractId: string;
    qualityId: string;
    incoterm: string;
    purchaseType: string;
    costingDate: any;
    costingId?: string;
    pcrContractId?: string;
    bnId?: string;
}

interface ICoalCostingFilterComponent {
    loadBargesOnClickHandler?: (values: ICoalCostingFilterInitialValues) => void;
    isBusinessNumberMandatory: boolean;
    initialValues?: ICoalCostingFilterInitialValues;
    contractType: ContractModesEnum;
    formRef: FormInstance<any>;
    setBargeData?: React.Dispatch<React.SetStateAction<CalculationTableDto[]>>
    pcrContractType?: ProcurementContractTypeEnum;
    costingType?: CostingTypesEnum
}
export const CoalCostingFilterComponent = (props: ICoalCostingFilterComponent) => {
    const { authContext } = useAuthState()
    const { costingType, setBargeData, loadBargesOnClickHandler, isBusinessNumberMandatory, formRef, contractType, initialValues, pcrContractType } = props;
    const [businessNumbers, setBusinessNumbers] = useState<BusinessAllocationDropdownDto[]>([]);
    const [purchaseContracts, setPurchaseContracts] = useState<PurchaseDropDownDto[]>([]);
    const [qualitiesData, setQualitiesData] = useState([]);
    const [purchaseTypesData, setPurchaseTypesData] = useState([]);
    const [buttonDisableHandler, setButtonDisableHandler] = useState(true);
    const businessNumberService = new BusinessNumberService();
    const purchaseContractService = new PurchaseContractService();
    const salesContractService = new SalesContractService();


    useEffect(() => {
        if (contractType === ContractModesEnum.SALES)
            getAllSaleContractsForDropDown();
        else getAllPurchaseContractsDropDown();

        getAllAllocatedBns();
    }, [contractType]);

    useEffect(() => {
        if (initialValues) {
            handleEditClick();
        };

        if (initialValues?.bnId && initialValues?.contractId) {
            formRef.setFieldValue('businessNumber', initialValues?.bnId);
            if (contractType === ContractModesEnum.SALES)
                getAllSaleContractsForDropDown();
            else getAllPurchaseContractsDropDown();
            businessNoOnChange(initialValues?.contractId);
            purchaseContractOnChange(initialValues?.contractId);
            qualityOnChange(initialValues?.qualityId);
            purchaseTypeOnChange(initialValues?.purchaseType);
            formRef.setFieldValue('incoterm', initialValues?.incoterm);
        } else {
            setPurchaseContracts([]);
            if (contractType === ContractModesEnum.SALES)
                getAllSaleContractsForDropDown();
            else getAllPurchaseContractsDropDown();
            formRef.setFieldValue('businessNumber', undefined);
        }

        if (initialValues?.contractId && !initialValues?.bnId) {
            if (contractType === ContractModesEnum.SALES)
                getAllSaleContractsForDropDown();
            else getAllPurchaseContractsDropDown();
            formRef.setFieldValue('contractId', initialValues?.contractId);
            purchaseContractOnChange(initialValues?.contractId);
            qualityOnChange(initialValues?.qualityId);
            purchaseTypeOnChange(initialValues?.purchaseType);
            formRef.setFieldValue('incoterm', initialValues?.incoterm);
        }

        // };

    }, [initialValues])

    if (initialValues?.costingDate) {
        initialValues.costingDate = moment(initialValues?.costingDate)
    }

    async function handleEditClick() {
        formRef.setFieldsValue({ contractId: initialValues?.contractId, costingDate: moment(initialValues?.costingDate) });
        await purchaseContractOnChange(initialValues?.contractId)
        formRef.setFieldsValue({ qualityId: initialValues?.qualityId })
        await qualityOnChange(initialValues?.qualityId)
        formRef.setFieldsValue({ purchaseType: initialValues?.purchaseType })
        purchaseTypeOnChange(initialValues?.purchaseType)
        formRef.setFieldValue('incoterm', initialValues?.incoterm);
        bargeOnClickHandler();
    }
    const getAllAllocatedBns = () => {
        businessNumberService.getBusinessAllocationDropDown(new ContractIdRequest('withOutContractId', authContext.defaultPlant)).then(res => {
            if (res.status) {
                setBusinessNumbers(res.data);
            }
        }).catch(err => {
            console.log('error: ' + err.message);
        })
    }

    const getAllPurchaseContractsDropDown = () => {
        purchaseContractService.getAllPurchaseContractsForDropDown({
            plantCode: authContext.defaultPlant,
            businessNoId: formRef.getFieldValue('businessNumber'),
            pcrContractType: pcrContractType,
            pcrContractId: formRef.getFieldValue('pcrContractId')
        }).then(res => {
            if (res.status) {
                setPurchaseContracts(res.data);
                if (!initialValues) {
                    setQualitiesData([]);
                    setPurchaseTypesData([]);
                }

            } else {
                setPurchaseContracts([]);
                setQualitiesData([]);
                setPurchaseTypesData([]);

            }
        }).catch(err => {
            setPurchaseContracts([]);
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    };

    const getAllSaleContractsForDropDown = () => {
        salesContractService.getAllSaleContractsForDropDown({
            plantCode: authContext.defaultPlant,
            businessNoId: formRef.getFieldValue('businessNumber'),
            pcrContractType: pcrContractType,
            pcrContractId: initialValues?.pcrContractId ? initialValues?.pcrContractId : formRef.getFieldValue('pcrContractId')
        }).then(res => {
            if (res.status) {
                setPurchaseContracts(res.data);
                if (!initialValues) {
                    setQualitiesData([]);
                    setPurchaseTypesData([]);
                }


            } else {
                setPurchaseContracts([]);
                setQualitiesData([]);
                setPurchaseTypesData([]);
            }
        }).catch(err => {
            setPurchaseContracts([]);
            setQualitiesData([]);
            setPurchaseTypesData([]);
            console.log('error: ' + err.message);
        })
    };

    const getQualityAndLaycanDropdown = async (pcId: string) => {
        if (contractType === ContractModesEnum.SALES) {
            salesContractService.getQualityAndLaycanDropdown(new ContractIdRequest(pcId, authContext.defaultPlant, undefined, pcrContractType, formRef.getFieldValue('businessNumber'))).then(res => {
                if (res.status) {
                    setQualitiesData(res.data);
                } else {
                    setQualitiesData([]);
                    setPurchaseTypesData([]);
                }
            }).catch(err => {
                setQualitiesData([]);
                setPurchaseTypesData([]);
                console.log('error: ' + err.message);
            });

        }
        else {

            purchaseContractService.getQualityAndLaycanDropdown(new ContractIdRequest(pcId, authContext.defaultPlant, undefined, pcrContractType, formRef.getFieldValue('businessNumber') )).then(res => {
                if (res.status) {
                    setQualitiesData(res.data);
                } else {
                    setQualitiesData([]);
                    setPurchaseTypesData([]);
                }
            }).catch(err => {
                setQualitiesData([]);
                setPurchaseTypesData([]);
                console.log('error: ' + err.message);
            });
        }

    }

    const getPcPurchaseTypesDropDown = (pcQualitiesId: string) => {
        if (contractType === ContractModesEnum.SALES) {

            salesContractService.getScSalesTypesDropDown(new PcPurchaseTypeReq(pcQualitiesId, pcrContractType)).then(res => {
                if (res.status) {
                    setPurchaseTypesData(res.data);
                } else {
                    setPurchaseTypesData([]);
                }
            }).catch(err => {
                setPurchaseTypesData([]);
                console.log('error: ' + err.message);
            })
        }
        else {
            purchaseContractService.getPcPurchaseTypesDropDown(new PcPurchaseTypeReq(pcQualitiesId, pcrContractType)).then(res => {
                if (res.status) {
                    setPurchaseTypesData(res.data);
                } else {
                    setPurchaseTypesData([]);
                }
            }).catch(err => {
                setPurchaseTypesData([]);
                console.log('error: ' + err.message);
            })

        }
    }

    const businessNoOnChange = async (contractId?: string) => {
        formRef.setFieldValue('contractId', contractId ? contractId : undefined);
        await purchaseContractOnChange(contractId);
        if (contractType === ContractModesEnum.SALES)
            getAllSaleContractsForDropDown();
        else getAllPurchaseContractsDropDown();
        setBargeData([]);
    };

    const purchaseContractOnChange = async (pcId: string) => {
        if (pcId) {

            await getQualityAndLaycanDropdown(pcId);
        }
        else {
            formRef.setFieldValue('qualityId', '');
            formRef.setFieldValue('purchaseType', '');
            console.log('6')
            setQualitiesData([]);
            setPurchaseTypesData([]);

        }
        setBargeData([]);
    }


    const qualityOnChange = async (pcQualitiesId: string) => {
        if (pcQualitiesId) {
            getPcPurchaseTypesDropDown(pcQualitiesId);
        } else {
            formRef.setFieldValue('purchaseType', '');
            setPurchaseTypesData([]);
        }
    }

    const purchaseTypeOnChange = (pcTypeId: string) => {
        if (pcTypeId) {
            // if (!purchaseTypesData.length) {
            //     formRef.setFieldValue('incoterm', initialValues?.incoterm);
            // } else {
            //     const pcType = purchaseTypesData?.filter(rec => rec.pcTypeId == pcTypeId)[0];
            //     formRef.setFieldValue('incoterm', pcType?.purchaseType);
            // };
            const pcType = purchaseTypesData?.filter(rec => rec.pcTypeId == pcTypeId)[0];
            formRef.setFieldValue('incoterm', pcType?.purchaseType);
            setButtonDisableHandler(false);
        } else {
            formRef.setFieldValue('incoterm', '');
            setButtonDisableHandler(true);
        }
    }

    const bargeOnClickHandler = () => {
        formRef.validateFields().then(formData => {
            loadBargesOnClickHandler(formData);
        });
    };



    return (
        <Card
      className="card-shadow"
      styles={{ body: { padding: '10px 15px' } }}
    >
            <Form form={formRef} layout='vertical' autoComplete='off' initialValues={initialValues}>

                <Row gutter={16}>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Business Number"
                            name="businessNumber"
                            rules={[{ required: isBusinessNumberMandatory ? true : false, message: 'Business Number is required' }]}
                        >
                            <Select
                                onChange={() => businessNoOnChange()}
                                allowClear
                                showSearch
                                placeholder='Select Business Number'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {businessNumbers.map(item => {
                                    return <Option value={item.businessNoId}>{item.businessNo}</Option>
                                })}
                            </Select>
                        </Form.Item>

                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label={`${contractType === ContractModesEnum.SALES ? `Sales` : `Purchase`}  Contract`}
                            rules={[{ required: true, message: `Purchase  Contract is required` }]}
                            name="contractId"
                        >
                            <Select
                                onChange={purchaseContractOnChange}
                                allowClear
                                showSearch
                                placeholder={`Select Purchase Contract No`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseContracts.map(item => {
                                    return <Option value={item.pcId}>{item.contractAndSupplier}</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label="Laycan (Quality)"
                            name="qualityId"
                            rules={[{ required: true, message: 'Laycan (Quality) is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={qualityOnChange}
                                showSearch
                                placeholder='Select Laycan'
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {qualitiesData?.map(item => {
                                    return <Option value={item.qualityId} style={{ width: 250 }}>{moment(item.laycan[0]).format('DD MMM YY') + ' - ' + moment(item.laycan[1]).format('DD MMM YY')} - ({`${item.quality}`})</Option>
                                })}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
                        <Form.Item
                            label={`${contractType === ContractModesEnum.SALES ? `Sales` : `Purchase`}  Type`}
                            name="purchaseType"
                            rules={[{ required: true, message: 'Contract Type is required' }]}
                        >
                            <Select
                                allowClear
                                onChange={purchaseTypeOnChange}
                                showSearch
                                placeholder={`Select ${contractType === ContractModesEnum.SALES ? `Sales` : `Purchase`}  Type`}
                                optionFilterProp="children"
                                filterOption={(input, option) => (option!.children as unknown as string).toString().toLocaleLowerCase().includes(input.toLocaleLowerCase())}>
                                <Option value={''}>Please Select</Option>
                                {purchaseTypesData?.map(item => {
                                    return <Option value={item?.pcTypeId}>{`${item?.purchaseType} ${item.jettyName ? `- (${item.jettyName})` : ''}`}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item name='incoterm' hidden>
                            <Input />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 5 }}>
                        <Form.Item label={'Date'} name='costingDate' rules={[{ required: true, message: 'Costing Date is required' }]}>
                            <DatePicker style={{ width: '80%' }} />
                        </Form.Item>
                    </Col>
                    <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 1 }} >
                        <Form.Item
                            style={{ marginTop: "24px" }}
                        >
                            <Button type="primary" disabled={buttonDisableHandler} onClick={bargeOnClickHandler}>Submit</Button>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>
        </Card>
    )
}

export default CoalCostingFilterComponent;