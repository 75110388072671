import { DownCircleFilled, MessageFilled, UpCircleFilled } from '@ant-design/icons'
import { ContractStatusEnum, DebitCreditService, NoteTypeReq, ReferenceFeatures } from '@exportx/shared-models-and-services'
import { Button, Card, Col, Drawer, Form, Radio, RadioChangeEvent, Row, Table, TableColumnsType, Tooltip, Typography } from 'antd'
import moment from 'moment'
import { useState, useEffect } from 'react'
import { useAuthState } from '../../../../common'
import DebitCreditNoteEntry from '../debit-credit-note-entry/debit-credit-note-entry'
import DebitCreditNoteChild from './debit-credit-note-child'
import { internationalFormattedValue } from '@exportx/ui-utils'
import CommentsPage from '../../../../common/comments/comments-page'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFlag } from '@fortawesome/free-solid-svg-icons'

interface IProps {
    noteType?: string;
    type?: ContractStatusEnum
}


export default function DebitCreditNoteApproval(props?: IProps) {

    const [data, setData] = useState<any[]>([])
    const [noteType, setNoteype] = useState<any>();
    const [drawerVisible, setDrawerVisible] = useState<boolean>(false)
    const [expandedIndex, setExpandedIndex] = useState([]);
    const { authContext } = useAuthState();
    const [form] = Form.useForm();
    const [commentsVisible, setCommentsVisible] = useState(false);
    const [featuresRefName, setFeaturesRefName] = useState<string>('');
    const [refrenceNo, setRefrenceNo] = useState<string>('');
    const [commentsTitle, setCommentsTitle] = useState<string>('');

    const service = new DebitCreditService();

    const options = [
        { label: 'Debit Note', value: 'Debit Note' },
        { label: 'Credit Note', value: 'Credit Note' },
    ];

    useEffect(() => {
        getData()
    }, [props.noteType])


    const getData = () => {
        const req = new NoteTypeReq(props.noteType, authContext.defaultPlant)
        service.getDataForApproval(req).then((res) => {
            if (res.status) {
                setData(res.data)
            } else {
                setData([])
            }
        }).catch((err) => {
            console.log('err', err)
        })
    };



    const radioOnChange = ({ target: { value } }: RadioChangeEvent) => {
        setNoteype(value);
    };

   let featuresRef;
   let pathToView;
  if (props.noteType === 'Debit Note') {
    pathToView = 'debit-detail-view'
    featuresRef = ReferenceFeatures.DEBIT_NOTE;
  } else {
    pathToView = 'credit-detail-view'
    featuresRef = ReferenceFeatures.CREDIT_NOTE;
  }

    const commentsData = (refName, title) => {
      setCommentsVisible(true);
      setFeaturesRefName(refName);
      setCommentsTitle(title);
    };
  
    const handleCommentsClick = (record) => {
      commentsData(featuresRef, 'All Comments');
      setRefrenceNo(record.debCreId);
    }

    const columns : TableColumnsType = [
        {
            title: `${props.noteType} No#`,
            dataIndex: 'refrenceNo',
            render: (value, record) => {
                return <>
                    <a href={`/#/${pathToView}?costing_id=${record.debCreId}`}>{value}</a>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    {record.priority === 'HIGH' ? <FontAwesomeIcon icon={faFlag} style={{ color: "red" }} /> : null}
                </>
            }
        },
        {
            title: 'Date',
            dataIndex: 'date',
            render: (text) => moment(text).format('DD\u2011MMM\u2011YYYY')
        },

        {
            title: 'Account',
            dataIndex: 'account'
        },
        {
            title: 'Amount',
            dataIndex: 'totalAmount',
            align: 'right',
            render: (value, record) => {
              return <>
              <Typography.Text>
              {internationalFormattedValue(value, 3)}
            </Typography.Text>
            <span className="currency-style">{`${authContext.defaultPlantCurrency}`}</span>
              </>
            }
        },
        {
            title: '',
            align: 'center',
            width: 50,
            render: (value, record) => {
              return (
                <>
                  <Tooltip placement="top" title="Comments">
                    <MessageFilled
                      className="message-icon"
                      onClick={() => handleCommentsClick(record)}
                    />
                  </Tooltip>
                </>
              );
            },
          },
    ]


    const setIndex = (expanded, record) => {
        const expandedRows = []
        if (expanded) {
            expandedRows.push(record?.debCreId);
            setExpandedIndex(expandedRows);
        } else {
            setExpandedIndex(expandedRows);
        }
    }
    const renderItems = (record: any, index, indent, expanded) => {
        return <>
            <DebitCreditNoteChild key={record.debCreId} debCreId={record.debCreId} />
        </>
    };
    return (
        <Card title={`${props.noteType} Approval`}>
            <Form form={form} autoComplete='off'>
                {/* <Row gutter={24} justify='center'>
                    <Col span={6} >
                        <Form.Item name='noteType' label='Note Type:'   >
                            <Radio.Group options={options} onChange={radioOnChange} value={noteType} />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Button type='primary' onClick={() => { getData() }}>Get Data</Button>
                    </Col>
                </Row> */}
                <Row>
                    <Table
                        // size='large'
                        className='contracts-list'
                        // scroll={{ x: true, y: 1000 }}
                        rowKey={(row) => row.debCreId}
                        columns={columns}
                        dataSource={data}
                        expandIconColumnIndex={0}
                        expandable={{
                            expandedRowRender: renderItems,
                            expandedRowKeys: expandedIndex,
                            onExpand: setIndex,
                            fixed: 'right'
                        }}
                        expandIcon={({ expanded, onExpand, record }) =>
                            expanded ? (
                                <UpCircleFilled
                                    onClick={(e) => onExpand(record, e)}
                                >
                                    Collapse
                                </UpCircleFilled>
                            ) : (
                                <DownCircleFilled onClick={(e) => onExpand(record, e)}>Expand</DownCircleFilled>
                            )
                        }
                    />
                </Row>


            </Form>
            <Drawer bodyStyle={{ paddingBottom: 80 }} title='Update' width={window.innerWidth > 768 ? '50%' : '85%'}
                onClose={() => setDrawerVisible(false)} open={drawerVisible} closable={true}>
                <DebitCreditNoteEntry />
            </Drawer>
            <Drawer
              bodyStyle={{ paddingBottom: 0 }}
              width={700}
              onClose={() => setCommentsVisible(false)}
              open={commentsVisible}
              closable={false}
            >
              <CommentsPage
                featuresRefNo={refrenceNo}
                featuresRefName={featuresRefName}
                commentsTitle={commentsTitle}
                setCommentsVisible={setCommentsVisible}
              />
            </Drawer>
        </Card>
    )
}
