import { AccountsService, BpCatCodeEnum, BusinessNoDropDownDto, BusinessNoPlantCodeReq, BusinessNumberService, PlantCodeReqDto, PortTypeEnum, ReportsService, StatusEnum } from "@exportx/shared-models-and-services";
import { Button, Card, Col, Descriptions, Divider, Drawer, Flex, Form, Input, Modal, Popover, Row, Select, Space, Table, TableColumnsType, Tabs, Tooltip, Typography, DatePicker } from "antd";
import { useCallback, useEffect, useMemo, useState } from "react";
import { AlertMessages, isPermissionExist, StatusTag, useAuthState } from "../../../common";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CloseOutlined, CheckCircleOutlined, InfoCircleOutlined, EditFilled, EditOutlined, SaveTwoTone, MessageFilled } from "@ant-design/icons";
import CommentsPage from "../../../common/comments/comments-page";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { LTCReportDocument } from "./ltc-report-document";
import debounce from 'lodash/debounce';
import { internationalFormattedValue } from "@exportx/ui-utils";

const { RangePicker } = DatePicker;

const LTCReports = () => {
  const navigate = useNavigate() 
  const [formRef] = Form.useForm();
  const { Option } = Select;
  const { authContext } = useAuthState();
  const [LTCReports, setLTCReports] = useState([]);
  const [edit,setEdit] = useState(false)
  const [businessDropDown, setBusinessDropDown] = useState<any[]>([]);
  const [selectedBusinessNo, setSelectedBusinessNo] = useState(null);
  const [displayCount, setDisplayCount] = useState(10); 
  const [displayedData, setDisplayedData] = useState([]); 
  const [open, setOpen] = useState<number | null>(null)
  const [commentsVisible, setCommentsVisible] = useState(false);
  const [featuresRefName, setFeaturesRefName] = useState<string>('');
  const [featureRefId, setFeaturesRefId] = useState<string>('')
  const [allVendors, setAllVendor] = useState<any>([])
  const [summeryData, setSummeryData] = useState([]); 
  const [dateRangeType, setDateRangeType] = useState<string | null>('this_year');
  const [selectedBusinessPartner,setSelectedBusinessPartner] = useState<any>(null);
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: moment().startOf('year'),
    to: moment().endOf('year'), 
  });



  const bnService = new BusinessNumberService()
  const reportsService = new ReportsService();
  const bpService = new AccountsService()

  useEffect(()=> {
    if(!isPermissionExist([892])){
      return navigate('/')
    }
  }, [])

  useEffect(()=>{
    getAllLTCReports()
    getBusinessNoDropDown()
    getVendorLedgers()
  },[])

  const getAllLTCReports = () => {

    bnService.getAllLTCReports({ plantCode: authContext.defaultPlant }).then(res => {
      if(res.status){
        setLTCReports(res.data);
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message);
    });
  }

  const getBusinessNoDropDown = () => {
    const req = new BusinessNoPlantCodeReq(authContext.defaultPlant)
    bnService.getBusinessNoDropDown(req).then(res => {
      if (res.status) {
        let dropdown = res.data.map(item => {
          let bnNo = item.businessNo.split(" ").pop()
          return {
            ...item,
            bnNo: bnNo
          }
        })
        setBusinessDropDown(dropdown)
      } else {
        console.log(res.internalMessage)
      }
    }).catch(err => {
      console.log(err.message)
    })
  }

  const getVendorLedgers = () => {
    bpService
      .getActLedForBpCatCode([BpCatCodeEnum.OTH])
      .then((res) => {
        if (res.status) {
          setAllVendor(res.data);
        } else {
          setAllVendor([]);
        }
      }).catch((err) => {
        AlertMessages.getErrorMessage(err.message)
      });
  };

  const currentDate = new Date()
  const formattedDate = moment(currentDate).format('YYYY-MM-DD HH:mm:ss');

  const handleSubmit = () => {

    const saveData = displayedData
    .filter(item => item.isUpdated === true || item.isNewRecord === true)
    .map(item => ({
      ...item,
      updatedBy: authContext.user.userName,
      updatedAt: formattedDate,
      createdBy: authContext.user.userName,
      createdAt: formattedDate,
      plantCode: authContext.defaultPlant,
      approvalLevel: item.managementApprovalStatus
    }));

    reportsService.createLTCReport(saveData).then(res => {
      if(res.status){
        getAllLTCReports()
        setEdit(null)
        AlertMessages.getSuccessMessage(res.internalMessage)
      } else {
        AlertMessages.getErrorMessage(res.internalMessage)
      }
    }).catch(err => {
      AlertMessages.getErrorMessage(err.message)
    })
  }

  const handleClick = (index) => {
    setEdit(index)
  };

  const ltcReportsData = (qualities: any[]) => {
    const ltcData = [];

    qualities?.forEach((rec) => {
    rec.contractData?.forEach((contractRecord, index) => {
      ltcData.push({
            id: contractRecord.id ? contractRecord.id : '',
            client: contractRecord.client,
            bpName: contractRecord.bpName,
            bpId: contractRecord.bpId,
            contractId: contractRecord.contractId,
            contractNo: contractRecord.contractNo,
            fileStatus: contractRecord.fileStatus,
            contractTypeId: contractRecord.contractTypeId,
            incoterm: contractRecord.incoterm,
            qualitiesId: contractRecord.qualitiesId,
            loadingRate: contractRecord.loadingRate,
            dischargeRate: contractRecord.dischargeRate,
            laycan: contractRecord.laycan,
            bgDemurrageId: contractRecord.bgDemurrageId,
            costingType: contractRecord.costingType,
            businessApprovalStatus: contractRecord.businessApprovalStatus,
            approvedBy: contractRecord.approvedBy,
            approvedDate: contractRecord.approvedDate,
            managementApprovalStatus: contractRecord.managementApprovalStatus,
            managementApprovedAt: contractRecord.managementApprovedAt,
            managementApprovedBy: contractRecord.managementApprovedBy,
            remarks: contractRecord.remarks,
            allocationId: contractRecord.allocationId,
            ltcApplicable: contractRecord.ltcApplicable,
            portType: contractRecord.portType,
            totalAmount: contractRecord.totalAmount,
            quantity: contractRecord.quantity,
            baseCurrency: contractRecord.baseCurrency,
            completedLoadTime: contractRecord.completedLoadTime,
            allowedTimeToLoad: contractRecord.allowedTimeToLoad,
            noOfDifference: contractRecord.noOfDifference,
            demurrage: contractRecord.demurrage,
            dispatch: contractRecord.dispatch,
            rowSpan: index === 0 ? rec.contractData.length : 0,
            bnId: rec.bnId,
            businessNo: rec.businessNo,
            mvName: rec.mvName,
            mvQty: rec.mvQty,
            blDates: rec.blDates,
            demurrageData: rec.demurrageData
          });
        });
      });
    return ltcData;
  };
  
  useEffect(() => {
    let filteredData = selectedBusinessNo || selectedBusinessPartner
    ? ltcReportsData(LTCReports).filter((item) => {
      const matchesBusinessNo = selectedBusinessNo ? item.bnId === selectedBusinessNo : true;
      const matchesBusinessPartner = selectedBusinessPartner ? item.bpId === selectedBusinessPartner : true;
      
      return matchesBusinessNo && matchesBusinessPartner;
    })
    : ltcReportsData(LTCReports);

    if (dateRangeValues.from && dateRangeValues.to) {
      const fromDate = moment(dateRangeValues.from.toDate()).startOf('day'); 
      const toDate = moment(dateRangeValues.to.toDate()).endOf('day');

      filteredData = filteredData.filter((item) => {
        if (!item.blDates) return false;
  
        const blDateArray = item.blDates.split(',').map((dateStr) => moment(dateStr.trim()));
  
        return blDateArray.some((blDate) => blDate.isBetween(fromDate, toDate, null, '[]'));
      });
    }

    let data = ltcReportsData(LTCReports);

    let finalData = []
     if(selectedBusinessPartner){
      const groupedData = {};
    filteredData.forEach((item) => {
        if (!groupedData[item.bnId]) {
            groupedData[item.bnId] = { parent: item, children: [] };
        }
        groupedData[item.bnId].children.push(item);
    });

    
    Object.values(groupedData).forEach(({ parent, children }) => {
        children.forEach((child, index) => {
          finalData.push({
                ...child,
                rowSpan: index === 0 ? children.length : 0,
            });
        });
    });
     }

     let result = selectedBusinessPartner ? finalData : filteredData; 
    

      if(!selectedBusinessNo && !selectedBusinessPartner && !dateRangeValues.from && !dateRangeValues.to){
        setDisplayedData([...data]);
      } else {
        setDisplayedData([...result]);
      }
    },[selectedBusinessNo,selectedBusinessPartner, LTCReports, dateRangeValues.from, dateRangeValues.to])

    const handleChange = (value: any, key: string, index: number) => {
      const newValue = value && value.target ? value.target.value : value;
    
      const newData = [...displayedData];
      newData[index][key] = newValue;
      if(newData[index].id){
        newData[index].isUpdated = true;
      } else {
        newData[index].isNewRecord = true;
      }
      setDisplayedData(newData);
    };

    const commentsData = (refName, refeatureId, index) => {
      setCommentsVisible(true);
      setFeaturesRefName(refName);
      setFeaturesRefId(refeatureId)
    }


    const processData = (data: any[]) => {
      return data.reduce((acc, { bgDemurrageId, client, costingType, totalAmount, baseCurrency }) => {
        const key = `${bgDemurrageId}-${client}-${costingType}`;
    
        if (!acc.uniqueEntries.has(key)) {
          acc.uniqueEntries.set(key, parseFloat(totalAmount));
    
          if (client === "BUYER") {
            if (costingType === "Demurrage") {
              acc.buyerTotalDemurrage.amount += parseFloat(totalAmount);
              acc.buyerTotalDemurrage.currency = baseCurrency;
            } else if (costingType === "Dispatch") {
              acc.buyerTotalDespatch.amount += parseFloat(totalAmount);
              acc.buyerTotalDespatch.currency = baseCurrency;
            }
          } else if (client === "SUPPLIER") {
            if (costingType === "Demurrage") {
              acc.supplierTotalDemurrage.amount += parseFloat(totalAmount);
              acc.supplierTotalDemurrage.currency = baseCurrency;
            } else if (costingType === "Dispatch") {
              acc.supplierTotalDespatch.amount += parseFloat(totalAmount);
              acc.supplierTotalDespatch.currency = baseCurrency;
            }
          } else {
            if (costingType === "Demurrage") {
              acc.ownerTotalDemurrage.amount += parseFloat(totalAmount);
              acc.ownerTotalDemurrage.currency = baseCurrency;
            } else if (costingType === "Dispatch") {
              acc.ownerTotalDespatch.amount += parseFloat(totalAmount);
              acc.ownerTotalDespatch.currency = baseCurrency;
            }
          }
        }
    
        return acc;
      }, {
        uniqueEntries: new Map<string, number>(),
        buyerTotalDemurrage: { amount: 0, currency: "" },
        buyerTotalDespatch: { amount: 0, currency: "" },
        supplierTotalDemurrage: { amount: 0, currency: "" },
        supplierTotalDespatch: { amount: 0, currency: "" },
        ownerTotalDemurrage: { amount: 0, currency: "" },
        ownerTotalDespatch: { amount: 0, currency: "" },
      });
    };
    
  const result = processData(displayedData);

  const columns: any = [
      {
        title: 'Business Number',
        dataIndex: 'businessNo',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
          const link = `/#/bn-detail-view?bn_Id=${record.bnId}`
          return <><a className="link" href={link}>{text} ({record.mvName})</a></>
        }
      },
      {
        title: 'Client',
        dataIndex: 'client',
      },
      {
        title: 'Business Partner',
        dataIndex: 'bpName',
      },
      {
        title: 'Contract No',
        dataIndex: 'contractNo',
      },
      {
        title: 'Incoterm',
        dataIndex: 'incoterm',
      },
      {
        title: 'Load Rate',
        children: [
          {
            title: 'Load Port',
            dataIndex: 'loadingRate',
            render: (value, record) => {
              return <span>{Math.round(value)}</span>
            }
          },
          {
            title: 'Discharge Port',
            dataIndex: 'dischargeRate',
            render: (value, record) => {
              return <span>{Math.round(value)}</span>
            }
          },
        ]
      },
      {
        title: 'Laycan',
        dataIndex: 'laycan',
        width: 200,
        render: (text, record) => {
          if (record.laycan) {
            const [fromDate, toDate] = record.laycan.split(' - ');
            if (fromDate && toDate) {
              return (
                <div>
                  {moment(fromDate).isValid() && moment(toDate).isValid()
                    ? `${moment(fromDate).format('DD-MMM-YYYY')} to ${moment(toDate).format('DD-MMM-YYYY')}`
                    : null}
                </div>
              );
            }
          } else {
            return null
          }
        }
      },
      {
        title: 'Load Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'demurrage',
            render: (text,record) => {
               return text && record.portType !== PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.dispatch}</> : null
            }
          }
        ]
      },
      {
        title: 'Discharge Port',
        children: [
          {
            title: 'Demurrage Rate/Dispatch Rate',
            dataIndex: 'dispatch',
            render: (text,record) => {
              return record.portType === PortTypeEnum.DISCHARGE_PORT ? <>{text} / {record.demurrage}</> : null 
           }
          }
        ]
      },
      {
        title: 'Stowage',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let totalHetchQty = record.mvQty.reduce((a, c) => a + Number(c.hatchQty), 0)
          return totalHetchQty ? totalHetchQty : null
        }
      },
      {
        title: 'Loaded',
        dataIndex: 'stowage',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text,record) => {
          let finalQty = record.mvQty.reduce((a, c) => a + Number(c.finalQty), 0)
          return finalQty ? finalQty : null
        }
      },
      {
        title: 'BL Date',
        dataIndex: 'blDates',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
           if(record.blDates && record.blDates !== null){
            let blDates = record.blDates?.split(',');
          return (
            <>
              {blDates.map((blDate, index) => (
                <div key={index}>
                  {moment(blDate).format('DD-MMM-YYYY')}
                </div>
              ))}
            </>
          );
           } else {
            return null
           }
        }
      },   
      {
        title: 'BL Month',
        dataIndex: 'blDates',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record) => {
          if (record.blDates && record.blDates !== null) {
            let blDates = record.blDates?.split(',');
      
            return (
              <>
                {blDates.map((blDate, index) => {
                  const parsedDate = moment.utc(blDate.trim());
                  return parsedDate.isValid() ? (
                    <div key={index}>
                      {parsedDate.format('MMMM')}
                    </div>
                  ) : null;
                })}
              </>
            );
          } else {
            return null;
          }
        }
      },            
      {
        title: 'LTC Applicable',
        dataIndex: 'ltcApplicable',
        render: (text, record, index) => {
          return edit === index && record.bgDemurrageId ? (
            <Select
              style={{ width: '100%' }}
              placeholder="LTC Applicable"
              value={record.ltcApplicable ?? null}
              onChange={(e)=> handleChange(e,'ltcApplicable',index)}
              allowClear
            >
              <Option value={1}>YES</Option>
              <Option value={0}>NO</Option>
            </Select>
          ) : (
            text !== null ? text === 1 ? 'YES' : 'NO' : null
          )
        }
      },
      {
        title: 'LTC Status',
        dataIndex: 'costingType',
      },
      {
        title: 'Port Type',
        dataIndex: 'status',
        render: (text,record) => {
          return record.portType || null
        }
      },
      {
        title: 'DEM Days',
        dataIndex: '',
        render: (text, record) => {
          let demDays = record.completedLoadTime > record.allowedTimeToLoad ? record.noOfDifference : 0;
          return demDays
        }
      },
      {
        title: 'DES Days',
        dataIndex: '',
        render: (text, record) => {
          let desDays = record.completedLoadTime < record.allowedTimeToLoad ? record.noOfDifference : 0;
          return desDays
        }
      },
      {
        title: 'LTC Amount LP',
        dataIndex: 'totalAmount',
        render: (text,record) => {
          return record.portType !== PortTypeEnum.DISCHARGE_PORT ? <span>{Number(record.totalAmount).toFixed(3)} {record.baseCurrency}</span> : null
        }
      },
      {
        title: 'LTC Amount DP',
        dataIndex: 'totalAmount',
        render: (text,record) => {
          return record.portType === PortTypeEnum.DISCHARGE_PORT ? <span>{Number(record.totalAmount).toFixed(3)} {record.baseCurrency}</span> : null
        }
      },
      {
        title: 'Business Approval Status',
        dataIndex: 'businessApprovalStatus',
        render: (text,record) => {
          return (record.businessApprovalStatus === "Send For Approval" || record.businessApprovalStatus === "Active" || record.businessApprovalStatus === "Draft") ? <>
          <StatusTag status={text} /> 
          {record.businessApprovalStatus === "Active" && (
            <Popover
              content={<>
                  <>
                    <Typography style={{ fontSize: 10 }}>Approved By <b>{record?.approvedBy}</b></Typography>
                    <Typography style={{ fontSize: 10 }}>Approved On <b>{record?.approvedDate ? moment(record?.approvedDate).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                  </>
              </>}
              title="Business Approval Status"
              placement="bottom"
            >
              <InfoCircleOutlined style={{ color: "#0295D4" }} />

            </Popover>
          )}
          </> : null
        }
      },
      {
        title: 'Management Approval Status',
        dataIndex: 'managementApprovalStatus',
        hidden: !isPermissionExist([893]),
        render: (text,record, index) => {
            if(record.businessApprovalStatus === "Active" && record.managementApprovalStatus !== "Active" ){
              return <>

                      <Popover
                        content={<>
                            <Form layout='vertical' form={formRef} autoComplete='off' style={{ width: '300px' }}>
                                <Form.Item name={"invoiceNo"} style={{ display: 'none' }}>
                                    <Input hidden />
                                </Form.Item>


                                <Form.Item
                                    label='remarks'
                                    name='remarks'
                                    style={{ width: '100%' }}
                                    rules={[
                                        {
                                            required: true, message: 'Enter Work Log'
                                        },
                                    ]}
                                >
                                    <Input.TextArea style={{ width: '100%' }} placeholder='Notes' onChange={(e) => handleChange(e,'remarks',index)} />
                                </Form.Item>

                                <Divider />

                                <Flex align='center' justify='flex-end'>
                                    <Space size={10}>

                                        <Button type="text" className='link' size='small' onClick={() => setOpen(undefined)}>Cancel</Button>
                                        <Button
                                          className="approve"
                                          disabled={!formRef.getFieldValue('remarks')}
                                          onClick={async () => {
                                            try {
                                              const newData = [...displayedData];
                                              if (newData[index]) {
                                                newData[index].managementApprovalStatus = "Active";
                                                newData[index].approvedAt = formattedDate;
                                                newData[index].approvedBy = authContext.user.userName;
                                                // newData[index].remarks = formRef.getFieldValue('remarks');
                                                newData[index].isNewRecord = true;
                                              }

                                              setDisplayedData(newData);

                                              handleSubmit();
                                            } catch (error) {
                                              console.error("Error during approval:", error);
                                              AlertMessages.getErrorMessage("Failed to approve the record.");
                                            }
                                          }}
                                        >
                                          <CheckCircleOutlined style={{ color: "#0B903C" }} /> Approve
                                        </Button>
                                    </Space>
                                </Flex>
                            </Form>
                        </>}
                        title="Work Log"
                        trigger="click"
                        open={index === open}
                        placement="bottom"
                    >
                            <Button icon={<CheckCircleOutlined />} className="approve" onClick={() => setOpen(index)}>Approve</Button>
                    </Popover>
              </>
            } else {
            if(record.managementApprovalStatus === "Active"){
              return <>
                    <StatusTag status={record.managementApprovalStatus} />
                    <Popover
                    content={<>
                      {/* {record.workLog?.map((log) => ( */}
                        <>
                          <Typography style={{ fontSize: 10 }}>Remarks <b>{record?.remarks}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Approved By <b>{record?.managementApprovedBy}</b></Typography>
                          <Typography style={{ fontSize: 10 }}>Approved On <b>{record?.managementApprovedAt ? moment(record?.managementApprovedAt).format('DD/MM/YYYY h:mm A') : ""}</b></Typography>
                        </>
                      {/* ))} */}
                    </>}
                    title="Management Approval Status"
                    placement="bottom"
                  >
                    <InfoCircleOutlined style={{ color: "#0295D4" }} />

                  </Popover>
                  </>
            } else {
              return ''
            }
          }
        }
      },
      {
        title: 'File Status',
        dataIndex: 'fileStatus',
        width: 140,
        render: (text,record, index) => {
          return edit === index ? (
            <Select
              style={{ width: '100%' }}
              placeholder="File status" 
              value={record.fileStatus || null}
              onChange={(e)=> handleChange(e,'fileStatus',index)}
              allowClear
            >
              {['Initated','Pending Confirmation','Confirmed', 'Payment pending','Completed'].map(item => {
                 return <Option value={item}>{item}</Option>
              })}
            </Select>
          ) : (
            text
          )
        }
      },
      {
        title: '',
        onCell: (record: any) => ({
          rowSpan: record.rowSpan
        }),
        render: (text, record, index) => {
          return <>
            <span style={{ display: "flex", alignItems: "center" }}>
                <PDFDownloadLink
                  document={<LTCReportDocument costingData={record.demurrageData}/>}
                  fileName={`LTCReport.pdf`}
                >
                  <Tooltip placement="topRight" title="LTC Report Document">
                      <FontAwesomeIcon
                          style={{
                              border: 'none',
                              fontSize: '14px',
                              padding: '7px',
                              borderRadius: '50%',
                              color: '#0295D4',
                              backgroundColor: '#DEECF3',
                              marginTop: '5px'
                          }}
                          icon={faDownload} onClick={() => {
                          }}
                      />
                  </Tooltip>
                  </PDFDownloadLink> &nbsp;
                  <Tooltip placement="top" title='Comments'>
                      <MessageFilled className='message-icon' onClick={() => commentsData('LTC Report', `${record?.bnId}-${record?.mvName}`, index)} />
                  </Tooltip>
            </span>
          </>
        }
      },
      {
        title: 'Action',
        fixed: 'right',
        align: 'center',
        hidden: !isPermissionExist([894]),
        render: (text, record, index) => {
          return edit === index ? (
          <>
            <Tooltip placement="top" title="Save">
                <Button style={{ padding: '0px 3px' }}><SaveTwoTone
                    onClick={handleSubmit}
                    style={{ color: '#1890ff', fontSize: '18px' }}
                /></Button>
            </Tooltip> &nbsp;
            <Tooltip placement="top" title="Cancel">
            <Button style={{ padding: '0px 3px' }}>
            <CloseOutlined
                type="cancel"
                onClick={() => {
                  const newData = [...displayedData];
                  if (newData[index]) {
                    newData[index].fileStatus = null;
                  }
                  setDisplayedData(newData);
                  setEdit(null)}}
                style={{ fontSize: '18px' }}
            /></Button>
            </Tooltip>
          </>) : (
            <span style={{ display: "flex", alignItems: "center" }}>
                  <Tooltip placement="top" title="Edit"> &nbsp;
                    <EditFilled
                        type="edit"
                        onClick={() => {handleClick(index)}}
                        className="action-icons"
                    />
                  </Tooltip>
            </span>
          )
        }
      },
  ]

  const columns2: any = [
    {
      title: "",
      dataIndex: "label",
      render: (value, record) => {
        return <span style={{ fontWeight: 600 }}>{value}</span>
      }
    },
    {
      title: "Payable",
      dataIndex: "payable",
      align: "right",
    },
    {
      title: "Receivable",
      dataIndex: "receivable",
      align: "right"
    },
  ]

  const finalData = [
    {
      label: "Demurrage",
      payable: `${internationalFormattedValue(result.supplierTotalDemurrage.amount, 3)} ${result.supplierTotalDemurrage.currency}`,
      receivable: `${internationalFormattedValue(result.buyerTotalDemurrage.amount + result.ownerTotalDemurrage.amount, 3)} ${result.buyerTotalDemurrage.currency}`,
    },
    {
      label: "Dispatch",
      payable: `${internationalFormattedValue(result.supplierTotalDespatch.amount  + result.ownerTotalDespatch.amount, 3)} ${result.supplierTotalDespatch.currency}`,
      receivable: `${internationalFormattedValue(result.buyerTotalDespatch.amount, 3)} ${result.supplierTotalDespatch.currency}`,
    },
    {
      label: "Total",
      payable: internationalFormattedValue(result.supplierTotalDemurrage.amount + result.supplierTotalDespatch.amount + result.ownerTotalDespatch.amount, 3),
      receivable: internationalFormattedValue(result.buyerTotalDemurrage.amount + result.buyerTotalDespatch.amount + result.ownerTotalDemurrage.amount, 3)
    },
  ]

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];
  const handleDateRangeTypeChange = (value: string | null) => {
      setDateRangeType(value);
  
      if (value === 'custom') {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      if (!value) {
        setDateRangeValues({ from: null, to: null });
      }
      const now = moment();
      let fromDate: moment.Moment;
      let toDate: moment.Moment;
  
      switch (value) {
        case 'this_month':
          fromDate = now.clone().startOf('month');
          toDate = now.clone().endOf('month');
          break;
        case 'previous_month':
          fromDate = now.clone().subtract(1, 'month').startOf('month');
          toDate = now.clone().subtract(1, 'month').endOf('month');
          break;
        case 'this_quarter':
          fromDate = now.clone().startOf('quarter');
          toDate = now.clone().endOf('quarter');
          break;
        case 'previous_quarter':
          fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
          toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
          break;
        case 'this_year':
          fromDate = now.clone().startOf('year');
          toDate = now.clone().endOf('year');
          break;
        case 'previous_year':
          fromDate = now.clone().subtract(1, 'year').startOf('year');
          toDate = now.clone().subtract(1, 'year').endOf('year');
          break;
        default:
          return;
      }

  
      setDateRangeValues({ from: fromDate, to: toDate });
  };

  const handleDateRangeValuesChange = (
      dates: [any | null, any | null] | null,
    ) => {
      if (!dates || !dates[0] || !dates[1]) {
        setDateRangeValues({ from: null, to: null });
        return;
      }
  
      const [from, to] = dates;
      setDateRangeValues({ from, to });
    };
  
  return (<>
    <Card 
       className='card-radius'>
        <Form
          layout="vertical"
          form={formRef}
        >
        <Row justify="space-between" className="mb-12 mt-8" gutter={[0, 8]}>
          <Col xs={24} md={20}>
            <Flex gap={10} wrap="wrap">
            <Form.Item
            label="Business Number:"
            name="businessNumber"
          >
            <Select
                  style={{ width: 225 }}
                  placeholder="Business Number" 
                  value={selectedBusinessNo}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  onChange={(e) => setSelectedBusinessNo(e)}
                  showSearch
                  allowClear
              >
                  {businessDropDown.map(item => (
                      <Option value={item.bnId} key={item.bnId}>
                          {item.bnNo} {item.motherVesselName && `(${item.motherVesselName})`}
                      </Option>
                  ))}
              </Select> 
          </Form.Item>
            <Form.Item
              label="Business Partner:"
              name="businessPartner"
            >
              <Select
                style={{ width: 175 }}
                placeholder="Business Partner" 
                onChange={(e) => setSelectedBusinessPartner(e)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                showSearch
                allowClear
                >
                {allVendors.map((item, index) => (
                  <Option value={item.bp_id} key={index}>
                      {item.actLedName}
                  </Option>
              ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="BL Date:"
              name="dateRange"
            >
              <Flex flex="column" gap={10}>
                <Select
                    placeholder="Date Range"
                    style={{ width: 175 }}
                    allowClear
                    value={dateRangeType}
                    onChange={handleDateRangeTypeChange}
                  >
                    {dateRangeDropDown.map((item, index) => (
                      <Select.Option
                        value={item.value}
                        key={item.value + '-' + index}
                      >
                        {item.label}
                      </Select.Option>
                    ))}
                  </Select>
                  {dateRangeType === 'custom' && (
                    <DatePicker.RangePicker
                    style={{ width: 175 }}
                      value={[
                        dateRangeValues.from,
                        dateRangeValues.to,
                      ]}
                      onChange={handleDateRangeValuesChange}
                    />
                  )}
                </Flex>
                </Form.Item>
            </Flex>
          </Col>
        </Row>
        </Form>
        <Table
          className="contracts-list"
          columns={columns2}
          dataSource={finalData}
          pagination={false}
          rowClassName={(record) => (record.label === "Total" ? "total-row" : "")}
        />
        <div style={{ marginBottom: '20px' }}></div> 
        <Table
          className="contracts-list"
          columns={columns}
          dataSource={displayedData}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
     </Card>
     <Drawer
         bodyStyle={{ paddingBottom: 0 }}
         width={700}
         onClose={() => setCommentsVisible(false)}
         open={commentsVisible}
         closable={false}
     >
        <CommentsPage featuresRefNo={featureRefId} featuresRefName={featuresRefName} commentsTitle={'LTC Report'} setCommentsVisible={setCommentsVisible}/>
     </Drawer>
</>
)
}

export default LTCReports;