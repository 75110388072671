import { Col, Divider, Modal, Row, Select, Table, TablePaginationConfig } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { DatePicker } from 'antd';
import { BusinessTypesEnum, ReportsService } from "@exportx/shared-models-and-services";
import { title } from "process";
import { useAuthState } from "../../../../common";
import PoPerformanceNoBlBargeList from "./po-performance-barges";
import { internationalFormattedValue } from "@exportx/ui-utils";
// import DatePicker from "../../../../common/data-picker/date-picker";

interface POPerformanceProps {
  supplier: any
}

const { RangePicker } = DatePicker;
export const POPerformance = (props: POPerformanceProps) => {
  const { supplier } = props;
  const { Option } = Select;
  const { authContext } = useAuthState()
  const [poPerformance, setPoPerformance] = useState<any[]> ([])
  const [selectedSupplier, setSelectedSupplier] = useState<string>('');
  const [businessTyper, setBusinessTyper] = useState<string>('')
  const [dateRangeType, setDateRangeType] = useState<string | null>('this_year');
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(25);
  const [open, setOpen] = useState<boolean>(false)
  const [noBlBarge, setNoBlBarge] = useState([])
  const [dateRangeValues, setDateRangeValues] = useState<{
    from: any | null;
    to: any | null;
  }>({
    from: moment().startOf('year'),
    to: moment(), 
  });

  const reportService = new ReportsService();

  useEffect(()=>{
    getPoSupplierPerformance()
  },[
    currentPage,
    pageSize,
    selectedSupplier,
    businessTyper,
    dateRangeValues,
  ])

  let plantCode = authContext.defaultPlant === 'BKM' || authContext.defaultPlant === 'SDAM' ? true : false;

  const getPoSupplierPerformance = () => {
    let fromDate = dateRangeValues.from ? moment(dateRangeValues.from.toDate()).startOf('day').format('YYYY-MM-DD') : null
    let toDate = dateRangeValues.from ? moment(dateRangeValues.to.toDate()).endOf('day').format('YYYY-MM-DD') : null
    const req = {
      offset: (currentPage - 1) * pageSize, 
      limit: pageSize, 
      plantCode: authContext.defaultPlant,
      businessTyper: businessTyper,
      supplierId: selectedSupplier,
      dateRange: { fromDate: fromDate, toDate: toDate },
    }
    reportService.getPoSupplierPerformance(req).then(res => {
      if(res.status) {
        setPoPerformance(res.data?.poPerformance)
        setTotal(res.data?.total)
      }
    }).catch(err => {
      console.error(err.message)
    })
  }

  const columns: any = [
    {
      title: 'Supplier Name',
      dataIndex: 'bpName',
      width: 280,
    },
    {
      title: 'Mine Name',
      dataIndex: 'mineName',
      width: 280,
      render: (value, record) => {
        return record?.mines?.map((item, index) => {
          const mineCount = item?.blDates?.length || 1;

          return (
            <div key={index}>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  // height: '107px',
                  height: plantCode ? `144px` : '107px',
                  // backgroundColor: 'green',
                }}
              >
                {item.mineName}
              </div>
              {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
            </div>
          );
        });
      },
    },
    {
      title: 'Business Type',
      dataIndex: '',
      render: (value, record) => {
        if(record?.mines.length === 0) {
          return (
            <div 
            >
              <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                <span>Export Sales</span>
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>DMO Sales</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              </> : null}
              <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                <span>NA</span>
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
              <div style={{ height: '35px',display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                <span>Total</span>
              </div>
            </div>)
        }
        return record?.mines?.map((item, index) => {
          return (
              <div 
              >
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>Export Sales</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                {plantCode ? <>
                  <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                    <span>DMO Sales</span>
                  </div>
                </> : null}
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                <div style={{ height: '35px',display: 'flex', alignItems: 'center' }}>
                  <span>NA</span>
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }} />
                <div style={{ height: '35px',display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                  <span>Total</span>
                </div>
                {index !== record?.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0' }} />}
              </div>
        );
      });
      },
    },
    {
      title: 'January',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return <div style={{ height: '35px', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>-</div>;
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 0) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },      
    {
      title: 'February',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 1) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },    
    {
      title: 'March',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 2) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    }, 
    {
      title: 'April',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 3) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'May',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 4) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'June',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 5) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'July',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 6) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'August',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 7) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'September',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 8) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'October',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 9) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'November',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 10) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'December',
      dataIndex: '',
      width: 100,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
            const dateObj = new Date(blEntry.blDate);
            if (dateObj.getMonth() === 11) {
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'Total Quantity',
      dataIndex: '',
      width: 110,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0;
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate !== null){
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += blEntry.blQty;
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += blEntry.blQty;
              } else if(blEntry.businessType === 'NA'){
                naTotal += blEntry.blQty
              }
            }
          });

          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)
    
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {exportSalesTotal > 0 ? <span>{internationalFormattedValue(exportSalesTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                  {dmoSalesTotal > 0 ? <span>{internationalFormattedValue(dmoSalesTotal, 3)}</span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {naTotal > 0 ?<span>{internationalFormattedValue(naTotal, 3)}</span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span>{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
    {
      title: 'BL Not Assigned',
      dataIndex: '',
      width: 110,
      align: 'right',
      render: (_, record) => {
        if (!record?.mines?.length) return '-';
    
        return record.mines.map((mine, index) => {
          let exportSalesTotal = 0;
          let dmoSalesTotal = 0;
          let naTotal = 0;
          let total = 0
    
          mine.blDates.forEach((blEntry) => {
            if(blEntry.blDate === null){
              if (blEntry.businessType === 'Export Sales') {
                exportSalesTotal += Number(blEntry.blQty);
              } else if (blEntry.businessType === 'DMO Sales') {
                dmoSalesTotal += Number(blEntry.blQty);
              } else if(blEntry.businessType === 'NA'){
                naTotal += Number(blEntry.blQty);
              }
          }
          });
          total = exportSalesTotal + naTotal + (plantCode ? dmoSalesTotal : 0)

          let exportSales = mine.noBargeBlDates?.find(item => item.businessType === 'Export Sales')
          let dmoSales = mine.noBargeBlDates?.find(item => item.businessType === 'DMO Sales')
          let na = mine.noBargeBlDates?.find(item => item.businessType === 'NA')
          
          return (
            <div key={index}>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {exportSalesTotal > 0 ? <span 
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {setNoBlBarge([exportSales]);setOpen(true)}}
                >
                  {internationalFormattedValue(exportSalesTotal, 3)}
                </span> : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              {plantCode ? <>
                <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                  {dmoSalesTotal > 0 ? <span 
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {setNoBlBarge([dmoSales]);setOpen(true)}}
                  >
                    {internationalFormattedValue(dmoSalesTotal, 3)}
                  </span> : '-'}
                </div>
                <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              </> : null}
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                {naTotal > 0 ? <span 
                    className="link"
                    style={{ cursor: 'pointer' }}
                    onClick={() => {setNoBlBarge([na]);setOpen(true)}}
                  >
                    {internationalFormattedValue(naTotal, 3)}
                  </span>  : '-'}
              </div>
              <Divider style={{ margin: '0px 0', padding: '0px 0px' }}/>
              <div style={{ height: '35px',display: 'flex', justifyContent: 'flex-end', alignItems: 'center', fontWeight: 'bold' }}>
                {total > 0 ? <span
                  className="link"
                  style={{ cursor: 'pointer' }}
                  onClick={() => {setNoBlBarge([...mine.noBargeBlDates]);setOpen(true)}}
                >{internationalFormattedValue(total, 3)}</span> : '-'}
              </div>
              {index !== record.mines.length - 1 && <Divider style={{ borderColor: 'rgba(221, 221, 221, 0.67)', margin: '0px 0', padding: '0px 0px' }}/>}
            </div>
          );
        });
      },
    },
  ]

  const dateRangeDropDown = [
    { value: 'this_month', label: 'This Month' },
    { value: 'previous_month', label: 'Previous Month' },
    { value: 'this_quarter', label: 'This Quarter' },
    { value: 'previous_quarter', label: 'Previous Quarter' },
    { value: 'this_year', label: 'This Year' },
    { value: 'previous_year', label: 'Previous Year' },
    { value: 'custom', label: 'Custom Date Range' },
  ];

  const handleDateRangeTypeChange = (value: string | null) => {
    setDateRangeType(value);

    if (value === 'custom' || value === undefined) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const now = moment();
    let fromDate: moment.Moment;
    let toDate: moment.Moment;

    switch (value) {
      case 'this_month':
        fromDate = now.clone().startOf('month');
        toDate = now.clone().endOf('month');
        break;
      case 'previous_month':
        fromDate = now.clone().subtract(1, 'month').startOf('month');
        toDate = now.clone().subtract(1, 'month').endOf('month');
        break;
      case 'this_quarter':
        fromDate = now.clone().startOf('quarter');
        toDate = now.clone().endOf('quarter');
        break;
      case 'previous_quarter':
        fromDate = now.clone().subtract(1, 'quarter').startOf('quarter');
        toDate = now.clone().subtract(1, 'quarter').endOf('quarter');
        break;
      case 'this_year':
        fromDate = now.clone().startOf('year');
        toDate = now.clone().endOf('year');
        break;
      case 'previous_year':
        fromDate = now.clone().subtract(1, 'year').startOf('year');
        toDate = now.clone().subtract(1, 'year').endOf('year');
        break;
      default:
        return;
    }

    setDateRangeValues({ from: fromDate, to: toDate });

  };

  const handleDateRangeValuesChange = (
    dates: [any | null, any | null] | null,
  ) => {
    if (!dates || !dates[0] || !dates[1]) {
      setDateRangeValues({ from: null, to: null });
      return;
    }

    const [from, to] = dates;
    setDateRangeValues({ from, to });   
  };

  const handleSupplierChange = (value: string) => {
    setSelectedSupplier(value);
  };

  const handleBusinessTyper = (value: string) => {
    setBusinessTyper(value)
  }

  const handleTableChange = (pagination: TablePaginationConfig) => {
    setCurrentPage(pagination.current || 1);
    setPageSize(pagination.pageSize || 25);
  };

  return (
    <>
      <Row gutter={[10, 10]} style={{ marginBottom: 16 }}>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                  placeholder= "Filter By Supplier"
                  style={{ width: "100%" }}
                  onChange={(value) => handleSupplierChange(value)}
                  filterOption={(input, option) =>
                    (option!.children as unknown as string)
                      .toString()
                      .toLocaleLowerCase()
                      .includes(input.toLocaleLowerCase())
                  }
                  value={selectedSupplier || null}
                  allowClear
                  showSearch
                >
                  {supplier?.map((rec) => {
                    return (
                      <Option value={rec.bpId}>{rec.bpName}</Option>
                    );
                  })}
                </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                placeholder="Filter By Business Typer"
                style={{ width: "100%" }}
                onChange={(value) => handleBusinessTyper(value)}
                filterOption={(input, option) =>
                  (option!.children as unknown as string)
                    .toString()
                    .toLocaleLowerCase()
                    .includes(input.toLocaleLowerCase())
                }
                value={businessTyper || null}
                allowClear
                showSearch
              >
                {Object.keys(BusinessTypesEnum).map(key => {
                  return <Option value={BusinessTypesEnum[key]}>{BusinessTypesEnum[key]}</Option>
                })}
              </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              <Select
                placeholder="Date Range"
                style={{ width: "100%" }}
                allowClear
                value={dateRangeType}
                onChange={handleDateRangeTypeChange}
              >
                {dateRangeDropDown.map((item, index) => (
                  <Select.Option
                    value={item.value}
                    key={item.value + '-' + index}
                  >
                    {item.label}
                  </Select.Option>
                ))}
              </Select>
            </Col>
            <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 4 }}>
              {dateRangeType === 'custom' && (
                <DatePicker.RangePicker
                  style={{ width: "100%" }}
                  value={[
                    dateRangeValues.from,
                    dateRangeValues.to,
                  ]}
                  onChange={handleDateRangeValuesChange}
                />
              )}
            </Col>
          </Row>
          <Table
            className="procurement-list"
            columns={columns}
            dataSource={poPerformance}
            scroll={{ x: 'max-content' }}
            rowClassName={"main-row"}
            pagination={{
              current: currentPage,
              pageSize: pageSize, 
              total: total,
              showSizeChanger: true,
              showTotal: (total) => `Total ${total} items`,
            }}
            onChange={handleTableChange}
          />
          <Modal
            open={open}
            onCancel={() => setOpen(false)}
            title='No BL Dates Barge List'
            footer={null}

          >
            <PoPerformanceNoBlBargeList boBlBarges={noBlBarge} />
          </Modal>
    </>  
  )
}

export default POPerformance;